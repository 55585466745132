import { Badge, Box, Button, Paper, Typography } from "@mui/material";
import Header from "../../components/header";
import BarchartStacked from "../../components/BarchartStacked";
import LineChartGradient from "../../components/LineChartGradient";
import { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";

import StatsCount from "../../components/StatsCount";
import StatusList from "../../components/StatusList";
import { GetReportByFilter } from "../../data/repos/userRepo";
import { useMutation } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import debounce from "lodash.debounce";

const Dashboard = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [responseData, setResponseData] = useState({});
  const [allocatedLeadCount, setAllocatedLeadCount] = useState("");
  const [giveupLeadCount, setGiveupLeadCount] = useState("");
  const [callInProgressLead, setCallInProgressLeadCount] = useState("");
  const [unCalledLeadCount, setUncalledLeadCount] = useState("");
  const [giveupCalls, setGiveupCalls] = useState([]);
  const [allocatedCalls, setAllocatedCalls] = useState([]);
  
  const [period, setPeriod] = useState(30);

  const {
    today,
    yesterday,
    dayBeforeYesterday,
    lastWeek,
    last15days,
    lastMonth,
  } = getDates();

  // Debounce the setRequestParams function
  const debouncedSetRequestParams = debounce((startDate, endDate) => {
    setRequestParams(startDate, endDate);
  }, 300); // Adjust the debounce delay as needed

  const setRequestParams = (startDate, endDate) => {
    const request = {
      startdate: startDate,
      enddate: endDate,
      // filter: 1,
    };
    console.log("Request:", request);
    getReportByFilter.mutate(request);
  };

  const handleFilterByToday = () => {
    setSelectedButton(1);
    setRequestParams(yesterday, today);
    setPeriod(1);
  };

  const handleFilterByYesterday = () => {
    setSelectedButton(2);
    setRequestParams(dayBeforeYesterday, yesterday);
    setPeriod(1);
  };

  const handleFilterByWeak = () => {
    setSelectedButton(3);
    setRequestParams(lastWeek, today);
    setPeriod(7);
  };

  const handleFilterBy15Days = () => {
    setSelectedButton(4);
    setRequestParams(last15days, today);
    setPeriod(15);
    // debouncedSetRequestParams(last15days, today);
  };

  const handleFilterByMonth = () => {
    setSelectedButton(5);
    setRequestParams(lastMonth, today);
    setPeriod(30);
    // debouncedSetRequestParams(lastMonth, today);
  };

  const getReportByFilter = useMutation(GetReportByFilter, {
    onMutate: () => {},
    onSuccess: (dataRes, variables, context) => {
      console.log("ReportResponse:", dataRes?.responsedata);

      if (dataRes?.responsedata) {
        GetDataStatusCounts(dataRes?.responsedata);
        // setGiveupCalls(dataRes?.responsedata.filter((item) => item.statusname === "Give Up"));
      } else {
        toast.error("No data available for selected period");
      }

      // const giveupData = data.filter((item) => item.statusname === "Give Up")
      //   setResponseData(giveupData);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const GetDataStatusCounts = (apiResponse) => {
    setAllocatedLeadCount(
      apiResponse.filter((item) => item.statusname === "Allocated").length
    );
    setGiveupLeadCount(
      apiResponse.filter((item) => item.statusname === "Give Up").length
    );
    setUncalledLeadCount(
      apiResponse.filter((item) => !item.leadstatus.Valid).length
    );
    setCallInProgressLeadCount(
      apiResponse.filter((item) => item.leadstatus.Valid).length
    );
    setAllocatedCalls(apiResponse.filter((item) => item.statusname === "Allocated"));
    setGiveupCalls(apiResponse.filter((item) => item.statusname === "Give Up"));
  };

  function getDates() {
    const addLeadingZero = (num) => (num < 10 ? "0" + num : num);

    const today = new Date();
    const yesterday = new Date(today);
    const dayBeforeYesterday = new Date(today);
    const lastWeek = new Date(today);
    const last15days = new Date(today);
    const lastMonth = new Date(today);

    yesterday.setDate(today.getDate() - 1);
    dayBeforeYesterday.setDate(today.getDate() - 2);
    lastWeek.setDate(today.getDate() - 7);
    last15days.setDate(today.getDate() - 15);
    lastMonth.setDate(today.getDate() - 30);

    const formatDate = (date) =>
      `${addLeadingZero(date.getDate())}/${addLeadingZero(
        date.getMonth() + 1
      )}/${date.getFullYear()}`;

    return {
      today: formatDate(today),
      yesterday: formatDate(yesterday),
      dayBeforeYesterday: formatDate(dayBeforeYesterday),
      lastWeek: formatDate(lastWeek),
      last15days: formatDate(last15days),
      lastMonth: formatDate(lastMonth),
    };
  }

  useEffect(() => {
    setRequestParams(lastMonth, today);
    setSelectedButton(5);
  }, []);

  return (
    <Box>
      <Header title={"Dashboard"} subTitle={"Manage all the data chart here"} />
      <Box display="flex" width="100%" flexDirection="coloumn">
        <Box sx={{ flex: "2 0 70%", marginLeft: "10px" }}>
          //date selector button
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant={selectedButton === 1 ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleFilterByToday()}
              sx={{
                margin: "0 2px",

                maxHeight: "25px",
              }}
            >
              Today
            </Button>
            <Button
              variant={selectedButton === 2 ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleFilterByYesterday()}
              sx={{
                margin: "0 2px",

                maxHeight: "25px",
              }}
            >
              Yesterday
            </Button>

            <Button
              variant={selectedButton === 3 ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleFilterByWeak()}
              sx={{
                margin: "0 2px",

                maxHeight: "25px",
              }}
            >
              07 days
            </Button>

            <Button
              variant={selectedButton === 4 ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px", maxHeight: "25px" }}
              onClick={() => handleFilterBy15Days()}
            >
              15 days
            </Button>

            <Button
              variant={selectedButton === 5 ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleFilterByMonth()}
              sx={{ margin: "0 2px", maxHeight: "25px" }}
            >
              30 days
            </Button>
          </Box>
          //status
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={1}
            p={0}
          >
            <StatsCount
              title="Assigned leads"
              totalCount={allocatedLeadCount}
              increasePercentage={23}
              gridColumn="span 2"
              gridRow="span 1"
            />
            <StatsCount
              title="Give up leads"
              totalCount={giveupLeadCount}
              increasePercentage={23}
              gridColumn="span 2"
              gridRow="span 1"
            />
            <StatsCount
              title="Call in progress"
              totalCount={callInProgressLead}
              increasePercentage={23}
              gridColumn="span 2"
              gridRow="span 1"
            />
            <StatsCount
              title="Leads Not Called"
              totalCount={unCalledLeadCount}
              increasePercentage={23}
              gridColumn="span 2"
              gridRow="span 1"
            />
          </Box>
          //charts
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "15px 15px 0 0",
              px: "10px",
              py: "10px",
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  color: "#000000",
                }}
              >
                Assigned Leads
              </Typography>
            </Box>
            <Box>
              <Badge
                sx={{ mx: "20px" }}
                badgeContent={allocatedCalls.length}
                max={9999}
                color="error"
              ></Badge>
            </Box>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={1}
            p={0}
          >
            <Box
              className="grid-item"
              border="2px solid #f5f5f5"
              borderRadius="15px"
              height="45vh"
              gridColumn="span 2" // This spans two columns horizontally
            >
              {Object.keys(giveupCalls).length !== 0 ? (
                <BarchartStacked responseData={allocatedCalls} days= {period} />
              ) : (
                <div>No data available</div>
              )}
            </Box>
          </Box>
          {/* <Box
            className="grid-item"
            bgcolor="#ffffff"
            border="2px solid #f5f5f5"
            height="30vh"
            borderRadius="15px"
            gridColumn="span 2" // This spans two columns horizontally
            gridRow="span 1"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m="10px"
            >
              <Box display="flex" marginRight="10px" textAlign="start">
                <Typography
                  sx={{
                    color: "#000000",
                  }}
                >
                  Give Up Leads
                </Typography>
              </Box>
            </Box>

            <ResponsiveContainer width="100%" height="80%">
              <LineChartGradient />
            </ResponsiveContainer>
          </Box> */}
        </Box>

        <Box sx={{ flex: "2 0 30%", overflow: "auto" }}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={0}
            p={2}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#000000",
                borderRadius: "0 0 0 0",
                px: "10px",
                py: "10px",
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Recent Give Up Leads
                </Typography>
              </Box>
              <Box>
                <Badge
                  sx={{ mx: "20px" }}
                  badgeContent={giveupCalls.length}
                  max={9999}
                  color="error"
                ></Badge>
              </Box>
            </Box>
            <StatusList users={giveupCalls} />
          </Box>
        </Box>
      </Box>

      {/* <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gap={1}
        p={2}
      >
        <StatsCount
          totalCount={10}
          increasePercentage={23}
          gridColumn="span 2"
          gridRow="span 1"
        />
        <StatsCount
          totalCount={10}
          increasePercentage={23}
          gridColumn="span 2"
          gridRow="span 1"
        />
        <StatsCount
          totalCount={10}
          increasePercentage={23}
          gridColumn="span 2"
          gridRow="span 1"
        />
        <StatsCount
          totalCount={10}
          increasePercentage={23}
          gridColumn="span 2"
          gridRow="span 1"
        />
      </Box> */}
      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};
export default Dashboard;
