
export const getCurrentDateTime = () => {
  const currentDateTime = new Date();
  const currentDate = currentDateTime.toLocaleDateString();
  const currentTime = currentDateTime.toLocaleTimeString();

  return {
    currentDate,
    currentTime,
  };
};
export const formatCustomDate = (date) => {
  date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear() % 100; // Extract last two digits of the year
  
    const daySuffix = getDaySuffix(day);
  
    return `${day}${daySuffix} ${month} ${year}`;
  };
  
  export const formatCustomTime = (date) => {
    date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    const formattedHours = hours % 12 || 12; // Convert 0 to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  
    return `${formattedHours}:${formattedMinutes}${ampm}`;
  };
  
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };


export function DateTimeFormatter(datetime) {
  var [dateString, timeString] = datetime.split(' ');
  var [day, month, year] = dateString.split('/');
  var [hour, minute, second] = timeString.split(':');

  // Parse the datetime into a Date object
  var parsedDate = new Date(year, month - 1, day, hour, minute, second);

  // Check if the date is today
  if (isToday(parsedDate)) {
    // Calculate time ago if date is today
    var timeAgo = calculateTimeAgo(parsedDate);
    return [dateString, timeAgo];
  } else {
    // Format time for other dates
    return [dateString, formatTime(parsedDate)];
  }
}

const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
};

const calculateTimeAgo = (parsedDate) => {
  const now = new Date();
  const difference = now - parsedDate;
  let agoString = '';

  // Calculate time difference in seconds
  const seconds = Math.floor(difference / 1000);
  if (seconds < 60) {
    agoString = seconds + ' seconds ago';
  } else {
    // Calculate time difference in minutes
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      agoString = minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';
    } else {
      // Calculate time difference in hours
      const hours = Math.floor(minutes / 60);
      if (hours < 24) {
        agoString = hours === 1 ? '1 hour ago' : hours + ' hours ago';
      } else {
        // Calculate time difference in days
        const days = Math.floor(hours / 24);
        agoString = days === 1 ? '1 day ago' : days + ' days ago';
      }
    }
  }
  return agoString;
};

const formatTime = (parsedDate) => {
  let hours = parsedDate.getHours();
  let minutes = parsedDate.getMinutes();
  let amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hour to 12-hour format
  minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes < 10
  return hours + ':' + minutes + ' ' + amOrPm;
};
  