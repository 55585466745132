import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../components/theme";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { useEffect, useState } from "react";
import {
 
  ArrowBackRounded,
 
} from "@mui/icons-material";

import {useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useMutation } from "react-query";
import { GetReportByKey } from "../../data/repos/userRepo";


const ReportView = ({userId}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseData, setResponsedata] = useState([]);
  const [clickedLeadInfo, setClickedLeadInfo] = useState({});
  const navigate = useNavigate();
  const { staff, key, startDate, endDate } = useParams();
 

  const columnData = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 0.25,
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 0.5,
    },
  
    {
      field: "createdon",
      headerName: "Date",
      flex: 0.5,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.5,
    },
    {
      field: "possibility",
      headerName: "Possibility",
      flex: 0.5,
    },
    {
      field: "statusname",
      headerName: "Call Status",
      flex: 0.6,
    },
   
    
  ];
  const startDateString = startDate.toString();
  const endDateString = endDate.toString();

  // Split the date string by '-'
  const parts = startDateString.split('-');
  const endparts = endDateString.split('-');
  
  // Rearrange the parts to form a date string in dd/mm/yyyy format
  const formattedStartDateString = parts[0] + '/' + parts[1] + '/' + parts[2];
  const formattedEndDateString = endparts[0] + '/' + endparts[1] + '/' + endparts[2];
  



  useEffect(() => {
    const params = {
      startdate: formattedStartDateString,
      enddate: formattedEndDateString,
      filter:  parseInt(staff, 10),
      status: key
    };

    console.log("***** Staff report params *****", params);
  
    getReportDataMutation.mutate(params);
  }, []);


  const getReportDataMutation = useMutation(GetReportByKey, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      if(data?.responsedata !== null){
      setResponsedata(data?.responsedata);
      console.log("***** Staff call report response *****", data?.responsedata);
      toast.success(data.responsedescription);
      }else{
        toast.error("No valid data available");
      }
    },
    onError: (error) => {
      console.log("***** Staff call report Error response *****", error);
     
      toast.error(error.message);
    },
  });
  

  const handleGridItemClick = (params) => {
    console.log('Clicked row:', params);
    // Get the ID of the clicked row from the params
    const clickedItemId = params.row.id;
    
    console.log('Clicked item ID:', clickedItemId);
    navigate(`/u/${userId}/task/lead/${clickedItemId}`);
  };


  const handleGoBack = () => {
    navigate(-1);
  };

 
  return (
    <Box m="10px 20px">
      <Box>
        <Box sx={{ p: "20px", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleGoBack}
            sx={{ color: "#000000", mr: "15px" }}
          >
            {<ArrowBackRounded />}
          </IconButton>
         
          
          <Typography
            variant="h5"
            fontWeight="bold"
            color={colors.textcolor[100]}
          >
            {"Report"}
          </Typography>

          
        </Box>
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.bg[100],
            cursor: 'pointer',
          },
          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        rows={responseData} 
        columns={columnData} 
        onCellClick={handleGridItemClick}/>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};
export default ReportView;
