import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectionDropdown = ({
  label,
  options,
  value,
  onChange,
  onBlur,
  keyId,
  keyVal,
  fullWidth = true,
  
}) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(option => option.id === selectedValue);
    onChange(keyId, keyVal, selectedValue, selectedOption.name); // Pass both id and name to the parent component
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        htmlFor="selection-dropdown"
        sx={{
          transform: 'translate(0, -25px)',
          color: '#000000',
        }}
      >
        {label}
      </InputLabel>
      <Select
        fullWidth
        label={label}
        id="selection-dropdown"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        sx={{
          color: '#000000',
          height: '43px',
          '& .MuiInputLabel-root': {
            transform: 'translate(0, -25px)',
          },
          '& .MuiSelect-root': {
            color: '#000000',
            borderRadius: '15px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#f6f6f6',
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
          '& .MuiSvgIcon-root': {
            color: '#000000',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectionDropdown;
