import {
  BASEURL,
  AddUser,
  GetLeads,
  Login,
  UpdateLead,
  GetAllStaffs,
  GetDepartments,
  GetDesignations,
  UploadBulkLeadApi,
  GetAllMetaLeads,
  GetAllDataCount,
  AddStudentApi,
  BulkUpdateLead,
  StaffwiseCallStatus,
  StaffWiseLeadCount,
  GetReportByStatusKey,
  GiveUpLead,
  studentProfileUpdate,
  NameUpdate,
  GetLeadInfoById,
  GetCallStatus,
  LeadCallUpdate,
  GetReportByFilterApi,
  GetAllSourceInfo,
  GetAllSource,
  GetAllCountry,
  UpdateUser,
} from "../api";
import axios from "axios";

const ApiServices = axios.create({
  baseURL: BASEURL,
});

export const UserLogin = async (requestData) => {
  try {
    console.log("userData", requestData);
    const response = await ApiServices.post(Login, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      // Save the token to local storage
      localStorage.setItem("authToken", userData.responsedata.token);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const CreateEmployee = async (requestData) => {
  try {
    console.log("userData", requestData);
    let token = localStorage.getItem("authToken");
    console.log("current token", token);
    if (!token) {
      // Handle case when token is not available, e.g., redirect to login
      // navigate("/");
      throw new Error("Authentication token not found");
    }

    const response = await ApiServices.post(AddUser, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // Save the token to local storage
    const authorizationHeader = response.headers.get("Authorization");
    console.log("Updated Token:", authorizationHeader);
    console.log("All Headers:", response.headers);
    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("Updated Token:", authorizationHeader);
    }
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const UpdateEmployee = async (requestData) => {
  try {
    console.log("userData", requestData);
    let token = localStorage.getItem("authToken");
    console.log("current token", token);
    if (!token) {
      // Handle case when token is not available, e.g., redirect to login
      // navigate("/");
      throw new Error("Authentication token not found");
    }

    const response = await ApiServices.post(UpdateUser, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // Save the token to local storage
    const authorizationHeader = response.headers.get("Authorization");
    console.log("Updated Token:", authorizationHeader);
    console.log("All Headers:", response.headers);
    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("Updated Token:", authorizationHeader);
    }
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetAllUsers = async (requestData, navigate) => {
  try {
    let token = localStorage.getItem("authToken");
    console.log("current token", token);
    if (!token) {
      // Handle case when token is not available, e.g., redirect to login
      // Redirect to the login page
      navigate("/");
      throw new Error("Authentication token not found");
    }

    const response = await ApiServices.post(
      GetLeads,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log("All Available Headers:", response.headers);
    // Check if the Authorization header is present in the response
    const authorizationHeader = response.headers.get("Authorization");
    console.log("Updated Token:", authorizationHeader);
    console.log("All Headers:", response.headers);
    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("Updated Token:", authorizationHeader);
    }

    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error;
  }
};

export const UpdateLeadData = async (requestData) => {
  try {
    console.log("Lead Update", requestData);
    const token = localStorage.getItem("authToken");
    const response = await ApiServices.post(UpdateLead, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    console.log("response status code", response.status);

    // Check if the Authorization header is present in the response
    const authorizationHeader = response.headers.get("Authorization");

    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("latest Updated Token:", authorizationHeader);
    }

    if (response.status >= 200 && response.status < 300) {
      const updatedData = response.data;
      console.log("response", updatedData);
      return updatedData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const BulkUpdateLeadData = async (requestData) => {
  try {
    console.log("Lead Update", requestData);
    const token = localStorage.getItem("authToken");
    const response = await ApiServices.post(BulkUpdateLead, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    console.log("response status code", response.status);

    // Check if the Authorization header is present in the response
    const authorizationHeader = response.headers.get("Authorization");

    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("latest Updated Token:", authorizationHeader);
    }

    if (response.status >= 200 && response.status < 300) {
      const updatedData = response.data;
      console.log("response", updatedData);
      return updatedData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetStaffs = async (requestData) => {
  try {
    console.log("staffData", requestData);
    const response = await ApiServices.post(
      GetAllStaffs,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const satffsData = response.data;
      console.log("satffs response data", satffsData);
      return satffsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetDesignation = async (requestData) => {
  try {
    console.log("staffData", requestData);
    const response = await ApiServices.post(
      GetDesignations,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const satffsData = response.data;
      console.log("satffs response data", satffsData);
      return satffsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetDepartment = async (requestData) => {
  try {
    console.log("staffData", requestData);
    const response = await ApiServices.post(
      GetDepartments,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const satffsData = response.data;
      console.log("satffs response data", satffsData);
      return satffsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const UploadBulkData = async (formDataFile) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await ApiServices.post(UploadBulkLeadApi, formDataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    });
    console.log("response status code", response.status);

    // Check if the Authorization header is present in the response
    const authorizationHeader = response.headers.get("Authorization");

    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("latest Updated Token:", authorizationHeader);
    }

    if (response.status >= 200 && response.status < 300) {
      const updatedData = response.data;
      console.log("response", updatedData);
      return updatedData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetMetaLeads = async (requestData, navigate) => {
  try {
    let token = localStorage.getItem("authToken");
    console.log("current token", token);
    if (!token) {
      // Handle case when token is not available, e.g., redirect to login
      // Redirect to the login page
      navigate("/");
      throw new Error("Authentication token not found");
    }

    const response = await ApiServices.post(
      GetAllMetaLeads,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    const authorizationHeader = response.headers.get("Authorization");

    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("Updated Token:", authorizationHeader);
    }

    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error;
  }
};

export const GetLeadsCount = async (requestData) => {
  try {
    console.log("counts", requestData);
    const response = await ApiServices.get(
      GetAllDataCount,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const satffsData = response.data;
      console.log("satffs response data", satffsData);
      return satffsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const AddUserLead = async (requestData) => {
  try {
    console.log("userData", requestData);
    let token = localStorage.getItem("authToken");
    console.log("current token", token);
    if (!token) {
      // Handle case when token is not available, e.g., redirect to login
      // navigate("/");
      throw new Error("Authentication token not found");
    }

    const response = await ApiServices.post(AddStudentApi, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // Save the token to local storage
    const authorizationHeader = response.headers.get("Authorization");
    console.log("Updated Token:", authorizationHeader);
    console.log("All Headers:", response.headers);
    if (authorizationHeader) {
      // Save the token to local storage
      localStorage.setItem("authToken", authorizationHeader);
      console.log("Updated Token:", authorizationHeader);
    }
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetCallStatusByStaffId = async (requestData) => {
  try {
    console.log("staffwise call report", requestData);
    const response = await ApiServices.post(StaffwiseCallStatus, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("staff call report response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const StaffWiseLeadsCount = async (staffid) => {
  try {
    const response = await ApiServices.get(`${StaffWiseLeadCount}${staffid}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const leadsData = response.data;
      return leadsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetReportByKey = async (requestData) => {
  try {
    const response = await ApiServices.post(GetReportByStatusKey, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("***** Staff call userrepo report response *****", userData);
      return userData; // Return success data
    } else {
      console.log("***** Staff call userrepo report error *****", response);

      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const UpdateStudentProfile = async (requestData) => {
  try {
    console.log("studentprofileupdate", requestData);
    const response = await ApiServices.post(studentProfileUpdate, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("studentprofileUpdateResponse", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GiveUpLeadData = async (requestData) => {
  try {
    console.log("leadUpdateData", requestData);
    const response = await ApiServices.post(GiveUpLead, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const UpdateNameApiCall = async (requestData) => {
  try {
    const response = await ApiServices.post(NameUpdate, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("staff call report response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetLeadsById = async (leadId) => {
  try {
    const response = await ApiServices.get(`${GetLeadInfoById}${leadId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const leadsData = response.data;
      console.log("leads info data", leadsData);
      return leadsData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetCallStatusData = async (requestData) => {
  try {
    console.log("callstatus", requestData);
    const response = await ApiServices.post(
      GetCallStatus,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const callStatusData = response.data;
      console.log("call status response data", callStatusData);
      return callStatusData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const UpdateLeadStatus = async (requestData) => {
  try {
    console.log("leadUpdateData", requestData);
    const response = await ApiServices.post(LeadCallUpdate, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response status code", response.status);
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("response", userData);
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetReportByFilter = async (requestData) => {
  try {
    const response = await ApiServices.post(GetReportByFilterApi, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      console.log("***** Staff call userrepo report response *****", userData);
      return userData; // Return success data
    } else {
      console.log("***** Staff call userrepo report error *****", response);

      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetSourceData = async (requestData) => {
  try {
    const response = await ApiServices.get(
      GetAllSource,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const responseData = response.data;
      return responseData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetSourceInfoData = async (requestData) => {
  try {
    const response = await ApiServices.get(
      GetAllSourceInfo,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const responseData = response.data;
      return responseData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetCountryData = async (requestData) => {
  try {
    const response = await ApiServices.get(
      GetAllCountry,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const responseData = response.data;
      return responseData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};
