import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Header from "../../components/header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../components/theme";
import {
  CreateEmployee,
  GetDepartment,
  GetDesignation,
  GetStaffs,
  UpdateEmployee,
} from "../../data/repos/userRepo";
import { useMutation, useQuery } from "react-query";
import { formatDesignation, inputTextDateFormat } from "../../utls/commons";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Person2Outlined } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";

export default function ManageEmployees() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [staffResponseData, setStaffResponseData] = React.useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formValues, setFormValues] = useState({});
  const [designationResponseData, setDesignationResponseData] = useState([]);
  const [departmentResponseData, setDepartmentResponseData] = React.useState(
    []
  );
  const [selectedLead, setSelectedLead] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const innerBox = {
    flex: "1",
    margin: "15px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    department: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),
    address1: yup.string().required("required"),
    address2: yup.string().required("required"),
    dob: yup.string().required("required"),
    doj: yup.string().required("required"),
    bloodgroup: yup.string().required("required"),
    password: yup.string().required("required"),
    parentname: yup.string().required("required"),
    gender: yup.string().required("required"),
    nationality: yup.string().required("required"),
    designation: yup.string().required("required"),
  });

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData, variables, context) => {
        setStaffResponseData(staffData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { designations, isDesignationsLoading, isDesignationsError } = useQuery(
    "designation",
    GetDesignation,
    {
      onSuccess: (designations, variables, context) => {
        setDesignationResponseData(designations?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { department, isDepartmentLoading, isDepartmentError } = useQuery(
    "department",
    GetDepartment,
    {
      onSuccess: (department, variables, context) => {
        setDepartmentResponseData(department?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const mutation = useMutation(UpdateEmployee, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      toast.success(data.responsedescription);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const formattedData = staffResponseData.map((item) => ({
    ...item,
    designation: formatDesignation(item.designationname),
    department: formatDesignation(item.departmentname),
    departmentid: item.department,
    designationid: item.designation,
  }));

  const handleFormSubmit = (values) => {
    setFormValues("id",)
    mutation.mutate(values);
  };

  const handleClearForm = () => {
    setFormValues(initialValues);
  };

  const columnData = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        // return (
        // //   <div onClick={() => handleGridItemClick(params)}>{params.value}</div>
        // );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "doj",
      headerName: "Doj",
      flex: 1,
    },
  ];

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box sx={{ width: "100%" }} role="contentinfo">
      <Box style={innerBox}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="15px"
                rowGap="35px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 3",
                  },

                  "& .MuiInputLabel-root": {
                    color: "#333333", // Set the hint text color to dark color
                  },
                  "& input": {
                    color: "#000000",
                    borderRadius: "15px",
                    height: "10px",
                  },

                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: "#f6f6f6",
                    "& fieldset": {
                      borderColor: "transparent", // Remove border color
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent", // Remove border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent", // Remove border color on focus
                    },
                  },
                }}
              >
                <Box
                  sx={{ gridColumn: "span 3" }}
                  display="flex"
                  alignItems="center"
                >
                  <IconButton sx={{ color: colors.bg[100] }}>
                    <Person2Outlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.bg[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Basic Information
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  onBlur={handleBlur}
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      name: e.target.value,
                    }));
                  }}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 1" }}
                />
                <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                  <InputLabel
                    htmlFor="department"
                    sx={{
                      transform: "translate(0, -25px)",
                      color: "#000000", // Set the label color to black
                    }} // Set the label color to black
                  >
                    Department
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Department"
                    name="department"
                    value={values.department}
                    onChange={(e) => {
                      handleChange(e);
                      const selectedDepartment = departmentResponseData.find(
                        (department) => department.id === e.target.value
                      );
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        department: e.target.value,
                        departmentName: selectedDepartment.name,
                      }));
                    }}
                    sx={{
                      gridColumn: "span 1",
                      color: "#000000",
                      height: "43px",

                      "& .MuiInputLabel-root": {
                        transform: "translate(0, -25px)",
                      },
                      "& .MuiSelect-root": {
                        color: "#000000",
                        borderRadius: "15px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000000", // Set the icon color to black
                      },
                    }}
                    onBlur={handleBlur}
                    error={!!touched.department && !!errors.department}
                    helperText={touched.department && errors.department}
                  >
                    <MenuItem value="" disabled>
                      Select a department
                    </MenuItem>
                    {departmentResponseData.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      email: e.target.value,
                    }));
                  }}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Mobile Number"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      mobile: e.target.value,
                    }));
                  }}
                  value={values.mobile}
                  name="mobile"
                  error={!!touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address 1"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      address1: e.target.value,
                    }));
                  }}
                  value={values.address1}
                  name="address1"
                  error={!!touched.address1 && !!errors.address1}
                  helperText={touched.address1 && errors.address1}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address 2"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      address2: e.target.value,
                    }));
                  }}
                  value={values.address2}
                  name="address2"
                  error={!!touched.address2 && !!errors.address2}
                  helperText={touched.address2 && errors.address2}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="Password"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      password: e.target.value,
                    }));
                  }}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 1" }}
                />
                <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                  <InputLabel
                    htmlFor="designation"
                    sx={{
                      transform: "translate(0, -25px)",
                      color: "#000000", // Set the label color to black
                    }} // Set the label color to black
                  >
                    Designation
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Designation"
                    name="designation"
                    value={values.designation}
                    onChange={(e) => {
                      handleChange(e);
                      const selectedDesig = designationResponseData.find(
                        (desig) => desig.id === e.target.value
                      );
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        designation: e.target.value,
                        desigValue: selectedDesig.name,
                      }));
                    }}
                    sx={{
                      gridColumn: "span 1",
                      color: "#000000",
                      height: "43px",

                      "& .MuiInputLabel-root": {
                        transform: "translate(0, -25px)",
                      },
                      "& .MuiSelect-root": {
                        color: "#000000",
                        borderRadius: "15px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000000", // Set the icon color to black
                      },
                    }}
                    onBlur={handleBlur}
                    error={!!touched.designation && !!errors.designation}
                    helperText={touched.designation && errors.designation}
                  >
                    <MenuItem value="" disabled>
                      Select a designation
                    </MenuItem>
                    {designationResponseData.map((designation) => (
                      <MenuItem key={designation.id} value={designation.id}>
                        {designation.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  label="Date of Birth"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      dob: e.target.value,
                    }));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end"></InputAdornment>
                    ),
                  }}
                  value={values.dob}
                  name="dob"
                  error={!!touched.dob && !!errors.dob}
                  helperText={touched.dob && errors.dob}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  label="Date of Join"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      doj: e.target.value,
                    }));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end"></InputAdornment>
                    ),
                  }}
                  value={values.doj}
                  name="doj"
                  error={!!touched.doj && !!errors.doj}
                  helperText={touched.doj && errors.doj}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                  <InputLabel
                    htmlFor="gender"
                    sx={{
                      transform: "translate(0, -25px)",
                      color: "#000000", // Set the label color to black
                    }} // Set the label color to black
                  >
                    Gender
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        gender: e.target.value,
                      }));
                    }}
                    sx={{
                      gridColumn: "span 1",
                      color: "#000000",
                      height: "43px",

                      "& .MuiInputLabel-root": {
                        transform: "translate(0, -25px)",
                      },
                      "& .MuiSelect-root": {
                        color: "#000000",
                        borderRadius: "15px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000000", // Set the icon color to black
                      },
                    }}
                    onBlur={handleBlur}
                    error={!!touched.gender && !!errors.gender}
                    helperText={touched.gender && errors.gender}
                  >
                    <MenuItem value="" disabled>
                      Select a gender
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Blood Group"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      bloodgroup: e.target.value,
                    }));
                  }}
                  value={values.bloodgroup}
                  name="bloodgroup"
                  error={!!touched.bloodgroup && !!errors.bloodgroup}
                  helperText={touched.bloodgroup && errors.bloodgroup}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Father/Spouse Name"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      parentname: e.target.value,
                    }));
                  }}
                  value={values.parentname}
                  name="parentname"
                  error={!!touched.parentname && !!errors.parentname}
                  helperText={touched.parentname && errors.parentname}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Nationality"
                  InputLabelProps={{
                    sx: {
                      transform: "translate(0, -25px)",
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      nationality: e.target.value,
                    }));
                  }}
                  value={values.nationality}
                  name="nationality"
                  error={!!touched.nationality && !!errors.nationality}
                  helperText={touched.nationality && errors.nationality}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              <Box display="flex">
                <Box display="flex" justifyContent="start" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      borderRadius: "20px", // Set the border radius as needed
                      backgroundColor: "#000000", // Set the background color to black
                      color: "#ffffff", // Set the text color to white (optional)
                      "&:hover": {
                        backgroundColor: "#333333", // Change the background color on hover
                      },
                    }}
                  >
                    Update Employee
                  </Button>
                </Box>
                <Box display="flex" mt="20px" ml="15px">
                  <Button
                    type="reset"
                    variant="contained"
                    onClick={() => {
                      resetForm(); // Call resetForm to clear the form
                      handleClearForm(); // Assuming setFormValues is a function to reset your additional state
                    }}
                    sx={{
                      borderRadius: "20px", // Set the border radius as needed
                      backgroundColor: "#b71c1c", // Set the background color to red
                      color: "#f5f5f5", // Set the text color to white (optional)
                      "&:hover": {
                        backgroundColor: "#D50000", // Change the background color on hover
                      },
                    }}
                  >
                    Delete Employee
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );

  const handleRowClick = (params) => {
    toggleDrawer("right", true)({});
    setSelectedLead(params.row);
    if (params.row) {
      setInitialValues({
        id:params.row.id,
        name: params.row.name || "",
        department: params.row.departmentid || "",
        email: params.row.email || "",
        mobile: params.row.mobile || "",
        address1: params.row.address1 || "",
        address2: params.row.address2 || "",
        dob: params.row.dob || "",
        doj: params.row.doj || "",
        bloodgroup: params.row.bloodgroup || "",
        parentname: params.row.parentname || "",
        gender: params.row.gender || "",
        nationality: params.row.nationality || "",
        designation: params.row.designationid || "",
      });
    }
  };

  return (
    <Box m="10px 15px">
      <Header title={"Team"} subTitle={"View and manage all Leads data here"} />

      <Box
        m="1px 0 0 0"
        height="79vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            color: colors.bg[100],
            cursor: "pointer",

            // Change the border color of the right side of the cell
            borderBottom: "1px solid #f5f5f5",
          },

          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={formattedData}
          columns={columnData}
          //   onRowClick={toggleDrawer("right", true)}
          onRowClick={handleRowClick}
        />
      </Box>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            borderRadius: "20px 0 0 20px",
          },
        }}
      >
        {list("right")}
      </Drawer>
      <Toaster position="bottom-left" reverseOrder={false} />
    </Box>
  );
}
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
