import { Box, Button, Typography, useTheme } from "@mui/material";
import Header from "../../components/header";
import { tokens } from "../../components/theme";
import { DataGrid } from "@mui/x-data-grid";

import { QueryClient, useMutation, useQuery } from "react-query";

import {
  BulkUpdateLeadData,
  GetAllMetaLeads,
  GetAllUsers,
  GetMetaLeads,
  GetStaffs,
  UpdateLeadData,
} from "../../data/repos/userRepo";
import { useEffect, useState } from "react";
import { DateTimeFormatter, formatDate } from "../../utls/commons";

import DialogComponent from "../../components/dialogComponent";
import { useNavigate } from "react-router-dom";
import BulkAssignDialog from "../../components/BulkAssignDialog";
import toast, { Toaster } from "react-hot-toast";

const MetaLeads = () => {
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const theme = useTheme();
  const [responseData, setResponsedata] = useState([]);
  const [staffResponseData, setStaffResponseData] = useState([]);
  const [leadUpdateResponse, setLeadUpdateResponse] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [isButtonActive, setIsButtonActive] = useState(false); // State for button activation
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const formattedData = responseData.map((item) => ({
    ...item,
    createdon: DateTimeFormatter(item.createdon),
  }));

  const handleBoxClick = () => {
    setSelectedLead(selectedRowIds);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    console.log("Closing Dialog");
    setOpenDialog(false);
    setSelectedLead(null);
  };
  const handleUpdateStatus = (staffId, leadId) => {
    const requestData = {
      staffid: staffId,
      leadid: selectedRowIds,
      status: 3,
    };
    mutation.mutate(requestData);
  };

  const columnData = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "createdon",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "statusname",
      headerName: "Status",
      flex: 1,
    },
  ];

  const { data, isLoading, isError } = useQuery(
    "myData",
    () => GetMetaLeads(navigate),
    {
      onSuccess: (data, variables, context) => {
        setResponsedata(data?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData, variables, context) => {
        setStaffResponseData(staffData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const mutation = useMutation(
    (requestData) => BulkUpdateLeadData(requestData),
    {
      onSuccess: (data, variables, context) => {
        setLeadUpdateResponse(data?.responseData);
        // Optionally, trigger a refetch of data here
        queryClient.invalidateQueries("myData");
        toast.success(data.responsedescription);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
        toast.error(error.message);
      },
    }
  );

  useEffect(() => {
    console.log("Selected row IDs:", selectedRowIds);
    setIsButtonActive(selectedRowIds.length > 0);
    console.log("Button active:", isButtonActive);
  }, [selectedRowIds]);

  const handleSelectionChange = (selection) => {
    setSelectedRowIds(selection);
  };

  return (
    <Box m="10px 20px">
      <Header title={"Team"} subTitle={"View and manage all Leads data here"} />
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button
          color="primary"
          disabled={!isButtonActive} // Disable the button if it's not active
          onClick={handleBoxClick}
          sx={{
            borderRadius: "20px",
            px: "15px",
            height: "40px",
            mt: 1,
            fontWeight: "bold",
            color: isButtonActive ? "#ffffff" : "#000000",
            backgroundColor: isButtonActive ? "#2196f3" : "#90a4ae" ,
          }}
        >
          Assign Selected Leads
        </Button>
      </Box>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px",
            color: colors.bg[100],
            cursor: "pointer",
            borderBottom: "1px solid #f5f5f5",
          },
          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[400]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={formattedData}
          columns={columnData}
          onRowSelectionModelChange={handleSelectionChange}
        />
      </Box>

      {selectedLead && (
        <>
          <BulkAssignDialog
            open={openDialog}
            onClose={handleCloseDialog}
            leadInfo={selectedRowIds}
            staffs={staffResponseData}
            onUpdateStatus={handleUpdateStatus} // Pass the callback function
          />
        </>
      )}
      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};
export default MetaLeads;
