import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GroupIcon from "@mui/icons-material/Group";

const StatsCount = ({ title, totalCount, increasePercentage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 3,
        padding: 1,
        boxShadow: 0,
        border: "solid 1px #bdbdbd"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
     
        <Typography variant="h6" sx={{  color:"#000000" }}>{title}</Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold", color:"#000000" }}>
          {totalCount.toLocaleString("de-DE").replace('.', ',')}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", color: "#4caf50" }}>
          <TrendingUpIcon sx={{ marginRight: 1 }} />
          <Typography>+{increasePercentage}% from the last month</Typography>
        </Box>
      </Box>
    
    </Box>
  );
};

export default StatsCount;
