import { Routes, Route, useParams } from "react-router-dom";
import SideBar from "./sideBar";
import TopBar from "./topBar";
import Dashboard from "../pages/dashboard";
import Leads from "../pages/leads";
// import LeadAllocation from "../pages/leads/leadAllocation";
import Staffs from "../pages/staffs";
import CreateLead from "../pages/leads/createLead";
import MetaLeads from "../pages/leads/metaLeads";
import ReportsDashboard from "../pages/reports";
import ReportView from "../pages/reports/ReportView";
import UserDetails from "../pages/reports/userDetails";
import ReportGeneration from "../pages/reports/ReportGeneration";
import ManageEmployees from "../pages/staffs/manageEmployees";
import CallRecords from "../pages/reports/CallRecords";

function Home() {
  const { userId } = useParams();
 
  return (
    <div className="app">
      <SideBar userId={userId} />
      <main className="content">
        <div className="container">
          {/* <TopBar /> */}
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/staffs" element={<ManageEmployees />} />
            <Route path="/staff/create" element={<Staffs />} />       
            <Route path="/leads" element={<Leads userId={userId} />} />
            <Route path="/metaleads" element={<MetaLeads />} />
            <Route path="/createlead" element={<CreateLead />} />
            <Route path="/reports" element={<ReportGeneration userId={userId}/>} />
            <Route path="/perfomance/report" element={<ReportView userId={userId} />} />
            <Route path="/perfomance" element={<ReportsDashboard />} />
            <Route path="/perfomance/report/:staff/:key/:startDate/:endDate" element={<ReportView userId={userId} />} />
            <Route path="/task/lead/:id" element={<UserDetails />} />
            <Route path="/callrecords" element={<CallRecords userId={userId}/>} />
           
          </Routes>
        </div>
      </main>
    </div>
  );
}
export default Home;
