import { ColorModeContext, useMode } from "./components/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Login from "./components/login";
import Home from "./components/home";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from './globalcontext/store';
function App() {
  const [theme, colorMode] = useMode();
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CssBaseline />
          <div className="App">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/u/:userId/*" element={<Home />} />
            </Routes>
          </div>
        </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;
