import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortModel: [],
  paginationModel: { page: 0, pageSize: 10 },
  filters: {},
  responseData: [],
};

const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
  },
});

export const { setSortModel, setPaginationModel, setFilters, setResponseData } = listSlice.actions;

export default listSlice.reducer;
