import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/header";
import { tokens } from "../../components/theme";
import { DataGrid } from "@mui/x-data-grid";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";

import { QueryClient, useMutation, useQuery } from "react-query";

import {
  GetAllUsers,
  GetStaffs,
  UpdateLeadData,
} from "../../data/repos/userRepo";
import { useState } from "react";
import {
  DateTimeFormatter,
  formatCallStatus,
  formatDate,
  formatStaffName,
} from "../../utls/commons";

import DialogComponent from "../../components/dialogComponent";
import { useNavigate } from "react-router-dom";
import { GetAllCallRecords } from "../../data/repos/voxbayRepo";

const CallRecords = ({ userId }) => {
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const theme = useTheme();
  const [responseData, setResponsedata] = useState([]);
  const [staffResponseData, setStaffResponseData] = useState([]);
  const [leadUpdateResponse, setLeadUpdateResponse] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const colors = tokens(theme.palette.mode);

  //   const formattedData = responseData.map((item) => ({
  //     ...item,
  // createdon: DateTimeFormatter(item.createdon),
  // // lastupdatedon: DateTimeFormatter(item.lastupdatedon),
  // callstatus: formatCallStatus(item.callstatus),
  // staff: formatStaffName(item.agent_number),
  //   }));

  //   const handleBoxClick = (leadId) => {
  //     const clickedLeadInfo = responseData.find((data) => data.id === leadId);
  //     // if (clickedLeadInfo.status === 1) {
  //     setSelectedLead(clickedLeadInfo);
  //     console.log("clicked item ", clickedLeadInfo);
  //     setOpenDialog(true);
  //     // }
  //   };

  //   const handleCloseDialog = () => {
  //     console.log("Closing Dialog");
  //     setOpenDialog(false);
  //     setSelectedLead(null);
  //   };

  //   const handleUpdateStatus = (staffId, leadId, leadstate) => {
  //     console.log("handleUpdateStatus Called:", staffId);
  //     var requestData;
  //     if (leadstate === 6) {
  //       requestData = {
  //         staffid: staffId,
  //         leadid: leadId,
  //         status: 3,
  //         isgivenuplead: 1,
  //       };
  //     } else {
  //       requestData = {
  //         staffid: staffId,
  //         leadid: leadId,
  //         status: 3,
  //         isgivenuplead: 0,
  //       };
  //     }
  //     console.log("lead allocation request:", requestData);
  //     mutation.mutate(requestData);
  //     setResponsedata((prevData) =>
  //       prevData.map((data) => {
  //         if (data.id === leadId) {
  //           const updatedData = { ...data, status: 3, statusname: "Allocated" };
  //           console.log("updatedData:", updatedData);
  //           // updateLead(updatedData);
  //           return updatedData;
  //         }
  //         return data;
  //       })
  //     );
  //   };

  const columnData = [
    {
      field: "called_number",
      headerName: "Student",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "caller_number",
      headerName: "DID Number",
      flex: 0.75,
    },
    {
      field: "agent_number",
      headerName: "Staff",
      flex: 1,
    },
    {
      field: "callDate",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "conversationDuration",
      headerName: "Duration",
      flex: 0.75,
    },
    {
      field: "recording_URL",
      headerName: "Recording",
      flex: 1.75,
      renderCell: ({ row }) => {
        const { recording_URL } = row;
        return <AudioPlayer url={recording_URL} />;
      },
    },
    {
      field: "callStatus",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row: { callStatus } }) => {
        return (
          <Box
            display="flex"
            width="100%"
            m="0 auto"
            px="10px"
            py="5px"
            justifyContent="center"
            borderRadius="25px"
            backgroundColor={
              callStatus === "ANSWERED"
                ? "#4caf50"
                : callStatus === "NOANSWER"
                ? "#ff9800"
                : callStatus === "CANCEL"
                ? "#607d8b" //colors.redAccent[700]
                : callStatus === "Not Connected"
                ? "#9e9e9e"
                : callStatus === "Connected"
                ? "#3f51b5"
                : callStatus === "BUSY"
                ? "#ffc107"
                : callStatus === "CHANUNAVAIL"
                ? "#795548"
                : colors.greenAccent[700]
            }
            // onClick={() => handleBoxClick(id)}
            // style={{ cursor: "pointer" }}
          >
            <div
              //   onClick={() => handleBoxClick(id)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" color="#ffffff" sx={{ ml: "5px" }}>
                {callStatus}
              </Typography>
            </div>
          </Box>
        );
      },
    },
    {
      field: "callStartTime",
      headerName: "Call Start At",
      flex: 1,
    },
  ];

  const { data, isLoading, isError } = useQuery(
    "myData",
    () => GetAllCallRecords(),
    {
      onSuccess: (data, variables, context) => {
        setResponsedata(data?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData, variables, context) => {
        setStaffResponseData(staffData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  //   const mutation = useMutation((requestData) => UpdateLeadData(requestData), {
  //     onSuccess: (data, variables, context) => {
  //       setLeadUpdateResponse(data?.responseData);
  //       // Optionally, trigger a refetch of data here
  //       queryClient.invalidateQueries("myData");
  //     },
  //     onError: (error) => {
  //       // Handle errors here
  //       console.error("Error:", error.message);
  //     },
  //   });

  //   const handleGridItemClick = (params) => {
  //     const clickedItemId = params.row.id;
  //     console.log("Clicked item ID:", clickedItemId);
  //     navigate(`/u/${userId}/task/lead/${clickedItemId}`);
  //   };
  function AudioPlayer({ url }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);
  
    const handlePlay = () => {
      if (audioElement && audioElement !== document.activeElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
      setIsPlaying(true);
      setAudioElement(document.activeElement);
    };
  
    const handlePause = () => {
      setIsPlaying(false);
    };
  
    return (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        p="8px 10px" // Adjusted padding to give more space
      >
        <audio
          controls
          style={{ width: '100%' }}
          onPlay={handlePlay}
          onPause={handlePause}
        >
          <source src={url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Box>
    );
  }

  return (
    <Box m="10px 15px">
      <Header title={"Call Records"} subTitle={"View all call details here"} />

      <Box
        m="1px 0 0 0"
        height="79vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px",
            color: colors.bg[100],
            cursor: "pointer",

            // Change the border color of the right side of the cell
            borderBottom: "1px solid #f5f5f5",
          },

          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={responseData} columns={columnData}  rowHeight={70}/>
      </Box>
      {/* {selectedLead && (
       
          <DialogComponent
            open={openDialog}
            onClose={handleCloseDialog}
            leadInfo={selectedLead}
            staffs={staffResponseData}
            onUpdateStatus={handleUpdateStatus} // Pass the callback function
          />
       
      )} */}
    </Box>
  );
};
export default CallRecords;
