import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, IconButton, Menu, MenuItem, Typography, Button, ButtonGroup } from "@mui/material";
import { DoubleArrowRounded, ForkRightRounded, PersonRounded, PhoneIphoneRounded, SupportAgentRounded, TransferWithinAStation, TransformOutlined } from "@mui/icons-material";

const statusColor = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "warning";
    default:
      return "default";
  }
};

const UserCard = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = () => {
    handleClose();
  };

  return (
    <Card sx={{ width: "100%", boxShadow: "none", marginBottom: "8px", backgroundColor: "#ffffff", borderRadius: "10px", border: "1px solid", borderColor: "grey.500" }}>
      <CardContent sx={{ padding: "2px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ marginBottom: "-18px" }}>
            <Typography variant="h6" color="grey.900">
              <IconButton size="small">
                <PersonRounded sx={{ color: "grey.900" }} />
              </IconButton>
              {user.name}
            </Typography>

            <Typography variant="body2" color="grey.900">
              <IconButton size="small">
                <PhoneIphoneRounded sx={{ color: "grey.900" }} />
              </IconButton>
              {user.mobile}
            </Typography>

            <Typography variant="body2" color="grey.900">
              <IconButton size="small">
                <SupportAgentRounded sx={{ color: "grey.900" }} />
              </IconButton>
              {user.staff?.String}
            </Typography>
          </div>
          <div>
            <IconButton size="small" onClick={handleClick}>
              <ForkRightRounded sx={{ color: "grey.900" }} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleMenuItemClick}>Your Menu Item</MenuItem>
            </Menu>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const StatusList = ({ users }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    setPageCount(Math.ceil(users.length / itemsPerPage));
  }, [users, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          color="primary"
          onClick={() => handlePageChange(i)}
          sx={{ minWidth: "auto", mx: 1 }}
          size="small"
        >
          {i}
        </Button>
      );
    }
    return pageNumbers;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Box
      className="grid-item"
      bgcolor="#ffffff"
      border="2px solid #f5f5f5"
      py="1px"
      borderRadius="0 0 15px 15px"
      gridColumn="span 2"
      gridRow="span 1"
      sx={{ width: "100%" }} // Ensure the component stretches to fill the available width
    >
      <Box sx={{ maxHeight: "65vh", overflow: "auto", p: "8px" }}>
        {currentItems.map((user, index) => (
          <UserCard key={index} user={user} />
        ))}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt={0}>
        <ButtonGroup
          size="small"
          variant="outlined"
          color="primary"
          sx={{ width: "100%" }} // Ensure ButtonGroup fills the width
        >
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ flex: 1 }} // Equal width for Previous button
          >
            Previous
          </Button>
          {/* {renderPageNumbers()} */}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
            sx={{ flex: 1 }} // Equal width for Next button
          >
            Next
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default StatusList;
