import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sourceName: [],
  sourceInfoName: [],
  countryName: [],
  staffResponseData: [],
  callStatusResponseData: [],
  selectedValues: {},
  reportResponse: [],
  selectedRowId: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setSourceName(state, action) {
      state.sourceName = action.payload;
    },
    setSourceInfoName(state, action) {
      state.sourceInfoName = action.payload;
    },
    setCountryName(state, action) {
      state.countryName = action.payload;
    },
    setStaffResponseData(state, action) {
      state.staffResponseData = action.payload;
    },
    setCallStatusResponseData(state, action) {
      state.callStatusResponseData = action.payload;
    },
    setSelectedValues(state, action) {
      state.selectedValues = action.payload;
    },
    setReportResponse(state, action) {
      state.reportResponse = action.payload;
    },
    setSelectedRowId(state, action) {
        state.selectedRowId = action.payload;
      },
  },
});

export const {
  setSourceName,
  setSourceInfoName,
  setCountryName,
  setStaffResponseData,
  setCallStatusResponseData,
  setSelectedValues,
  setReportResponse,
  setSelectedRowId,
} = reportSlice.actions;

export default reportSlice.reducer;
