import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Divider, Slide } from "@mui/material";

function BulkAssignDialog(props) {
  const { onClose, leadInfo, selectedValue, open, staffs, onUpdateStatus } =
    props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
    console.log("staffId", value.id);
    console.log("leadids", leadInfo);
    onUpdateStatus(value.id, leadInfo);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog
      maxWidth="xs"
      sx={{ "& .MuiDialog-paper": { width: "90%", borderRadius: 5 } }}
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ fontSize:'16px', backgroundColor: '#263238', borderRadius: '5px 5px 0 0', textAlign: 'center' }}
      >Allocate Lead</DialogTitle>
      <List  sx={{ backgroundColor: 'black', borderRadius: '0 0 5px 5px',  overflow: "auto", maxHeight: "300px" }}>
        {staffs.map((staff, index) => (
             <React.Fragment key={staff.id}>
          <ListItem disableGutters key={staff.id}>
            <ListItemButton onClick={() => handleListItemClick(staff)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={staff.name} />
            </ListItemButton>
          </ListItem>
          {index < staffs.length - 1 && (
          <Divider variant="middle" component="li" sx={{ backgroundColor: 'white' }} />
        )}
      </React.Fragment>
        ))}
      </List>
    </Dialog>
  );
}

BulkAssignDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  leadInfo: PropTypes.array.isRequired,
  staffs: PropTypes.array.isRequired,
  onUpdateStatus: PropTypes.func.isRequired, // Add the prop type for the callback
};

export default BulkAssignDialog;
