import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../../components/header";
import FactCard from "../../components/factCard";
import {
  ArrowOutwardRounded,
  AssignmentIndRounded,
  DateRangeOutlined,
  DateRangeRounded,
  Person2Rounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import NumberCard from "../../components/numberCard";
import { Formik } from "formik";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Person2Outlined } from "@mui/icons-material";

import {
  GetCallStatusByStaffId,
  GetStaffs,
  StaffWiseLeadsCount,
  UpdateStudentProfile,
} from "../../data/repos/userRepo";
import { tokens } from "../../components/theme";
import PieChart from "../../charts/pieChart";
import { format } from "date-fns";
import { margin } from "@mui/system";

const ReportDashBoard = ({ userId }) => {
  const { id } = useParams();
  const studentId = parseInt(id, 10);
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportResponse, setReportResponse] = useState({});
  const [leadsCountResponse, setLeadsCountResponse] = useState({});
  const [staffResponseData, setStaffResponseData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(0);
  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [total, setTotal] = useState(0);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  useEffect(() => {
    setTodaysDate();
   
  }, []);

  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",

    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const [selectedButton, setSelectedButton] = useState(1);

  const handleFilterByToday = () => {
    setSelectedButton(1);
    setTodaysDate();
  };

  const handleFilterByYesterday = () => {
    setSelectedButton(2);
    setYesterdayDate();
  };

  const handleFilterByWeak = (staffId) => {
    setSelectedButton(3);
    setDateRange(7, staffId);
  };

  const pieBoxStyle = {
    width: "100%",
    margin: "10px",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const innerBox = {
    flex: "1",
    margin: "15px",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    padding: "10px",
  };

  const setDateRange = (days, staffId) => {
    // Get today's date
    const startdate = new Date();
    startdate.setDate(startdate.getDate() - days);
    // Get yesterday's date
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

    const formattedStartDate = dateFormatter.format(startdate);
    const formattedEndDate = dateFormatter.format(endDate);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    console.log("selected user", staffId);
    const reportRequestObj = {
      startdate: formattedStartDate,
      enddate: formattedEndDate,
      filter: staffId,
    };

    console.log("report requestObj:", reportRequestObj);

    if (staffId !== undefined && staffId !== null && staffId !== "") {
      getReportMutation.mutate(reportRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const setTodaysDate = () => {
    // Get today's date
    const today = new Date();

    // Get tommorow's date
    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);

    // Format dates to dd/MM/yyyy format
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

    const formattedToday = dateFormatter.format(today);
    const formattedTommorow = dateFormatter.format(tommorow);

    console.log("Today's date:", formattedToday); // Output: Today's date: 16/02/2024
    console.log("Tommorows's date:", formattedTommorow); // Output: Tommorow's date: 17/02/2024

    setStartDate(formattedToday);
    setEndDate(formattedTommorow);

   

    // const staffId = JSON.parse(localStorage.getItem("loginInfo")).id;
    const staffId = selectedStaff;
    console.log("clicked staff:", selectedStaff);
    const reportRequestObj = {
      startdate: formattedToday,
      enddate: formattedTommorow,
      filter: selectedStaff,
    };

    console.log("report requestObj:", reportRequestObj);

    if (staffId !== undefined && staffId !== null && staffId !== "") {
      getReportMutation.mutate(reportRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const setYesterdayDate = () => {
    // Get today's date
    const today = new Date();
    today.setDate(today.getDate() + 1);

    // Get yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // Format dates to dd/MM/yyyy format
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

    const formattedToday = dateFormatter.format(today);
    const formattedYesterday = dateFormatter.format(yesterday);

    console.log("Today's date:", formattedToday); // Output: Today's date: 16/02/2024
    console.log("Yesterday's date:", formattedYesterday); // Output: Yesterday's date: 15/02/2024

    setStartDate(formattedYesterday);
    setEndDate(formattedToday);

    const staffId = selectedStaff;
    const reportRequestObj = {
      startdate: formattedYesterday,
      enddate: formattedToday,
      filter: staffId,
    };

    console.log("report requestObj:", reportRequestObj);

    if (staffId !== undefined && staffId !== null && staffId !== "") {
      getReportMutation.mutate(reportRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const handleItemClick = (item) => {
    let formattedStartDate = "";
    let formattedEndDate = "";
    if (formValues.startdate !== undefined && formValues.startdate !== "") {
      formattedStartDate = format(new Date(formValues.startdate), "dd-MM-yyyy");
      formattedEndDate = format(new Date(formValues.enddate), "dd-MM-yyyy");
    } else {
      // Assuming startDate format is "DD/MM/YYYY"
      const [day, month, year] = startDate.split("/");
      const startDateObject = new Date(`${year}-${month}-${day}`);

      // Assuming endDate format is "DD/MM/YYYY"
      const [day2, month2, year2] = endDate.split("/");
      const endDateObject = new Date(`${year2}-${month2}-${day2}`);
      console.log("time:", startDateObject);

      formattedStartDate = format(new Date(startDateObject), "dd-MM-yyyy"); // Use startDate state
      formattedEndDate = format(new Date(endDateObject), "dd-MM-yyyy"); // Use endDate state
    }

    navigate(
      `/u/${userId}/perfomance/report/${selectedStaff}/${item}/${formattedStartDate}/${formattedEndDate}`
    );
  };

  const initialProfileValues = {
    startdate: "",
    enddate: "",
  };



  const onProfileSubmitted = (values, formikBag) => {
    setFormValues(values);
    setSelectedStaff(values.staff);
    const staffId = selectedStaff;

    const reportRequestObj = {
      startdate: reformatDate(values.startdate),
      enddate: reformatDate(values.enddate),
      filter: selectedStaff,
    };

    console.log("report requestObj:", reportRequestObj);

    if (staffId !== undefined && staffId !== null && staffId !== "") {
      getReportMutation.mutate(reportRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const getReportMutation = useMutation(GetCallStatusByStaffId, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setReportResponse(data?.responsedata);
      const sum = Object.values(reportResponse).reduce((acc, currentValue) => acc + currentValue, 0) 
      setTotal(sum);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const {} = useQuery(
    ["getStaffWiseLeads", selectedStaff], // Specify a unique key for this query including staffid
    () => StaffWiseLeadsCount(selectedStaff), // Pass requestData and staffid to the function
    {
      onSuccess: (leadcounts, variables, context) => {
        setLeadsCountResponse(leadcounts?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
        toast.error(error.message);
      },
    }
  );

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData, variables, context) => {
        setStaffResponseData(staffData?.responsedata);
        const firstItem = staffData?.responsedata[0].id;
        setSelectedStaffName(staffData?.responsedata[0].name);
        setSelectedStaff(firstItem);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  // Helper function to format date as MM/DD/YYYY
  const formatDate = (date) => {
    // Extract day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
    const year = date.getFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
  };

  const reformatDate = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    } else {
      return "Invalid Date";
    }
  };

  return (
    <Box>
      <Header title={"Reports"} subTitle={"Generate reports by date range"} />
      <Box display="flex" borderRadius="15px 15px 0 0">
        <Box flex="2">
          <Box style={innerBox} margin="20px 0 0 0">
            <Formik
           
              initialValues={initialProfileValues}
             
              onSubmit={(values, formikBag) => {
                onProfileSubmitted(values, formikBag);
              }}
              validator={() => ({})}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="15px"
                    rowGap="35px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },

                      "& .MuiInputLabel-root": {
                        color: "#333333", // Set the hint text color to dark color
                      },
                      "& input": {
                        color: "#000000",
                        borderRadius: "15px",
                        height: "10px",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent", // Remove border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Remove border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Remove border color on focus
                        },
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      type="date"
                      label="Start Date"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          startdate: e.target.value,
                        }));
                      }}
                      value={values.startdate}
                      name="startdate"
                      error={!!touched.startdate && !!errors.startdate}
                      helperText={touched.startdate && errors.startdate}
                      sx={{
                        gridColumn: "span 1",
                      }}
                    />
                    <TextField
                      fullWidth
                      type="date"
                      label="End Date"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          enddate: e.target.value,
                        }));
                      }}
                      value={values.enddate}
                      name="enddate"
                      error={!!touched.enddate && !!errors.enddate}
                      helperText={touched.enddate && errors.enddate}
                      sx={{
                        gridColumn: "span 1",
                      }}
                    />
                    <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                      <InputLabel
                        htmlFor="staff"
                        sx={{
                          transform: "translate(0, -25px)",
                          color: "#000000",
                        }}
                      >
                        Staff
                      </InputLabel>

                      <Select
                        fullWidth
                        label="Staff"
                        name="staff"
                        value={selectedStaff.name}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedStaff = staffResponseData.find(
                            (staff) => staff.id === e.target.value
                          );
                          console.log("Selected staff:", selectedStaff);
                          setSelectedStaffName(selectedStaff.name);
                          setSelectedStaff(selectedStaff.id); // Update selectedStaff Id with the new staff id
                          console.log("Selected staff ID:", selectedStaff.id);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            staff: e.target.value,
                            staffValue: selectedStaff.name,
                          }));

                          handleFilterByWeak(selectedStaff.id);
                        }}
                        sx={{
                          gridColumn: "span 1",
                          color: "#000000",
                          height: "43px",
                          "& .MuiInputLabel-root": {
                            transform: "translate(0, -25px)",
                          },
                          "& .MuiSelect-root": {
                            color: "#000000",
                            borderRadius: "15px",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f6f6f6",
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000000",
                          },
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="" disabled>
                          Select a staff
                        </MenuItem>
                        {staffResponseData.map((staff) => (
                          <MenuItem key={staff.id} value={staff.id}>
                            {staff.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{
                        gridColumn: "span 1",
                        borderRadius: "25px", // Set the border radius as needed
                        backgroundColor: "#000000", // Set the background color to black
                        color: "#ffffff", // Set the text color to white (optional)
                        "&:hover": {
                          backgroundColor: "#333333", // Change the background color on hover
                        },
                      }}
                    >
                      Generate
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>

          <Box
            m="0px 1px"
            display="flex"
            minHeight="55vh"
            maxHeight="85vh"
            justifyContent="space-between"
          >
            <Box
              width="100%"
              height="100%"
              justifyContent="space-between"
              borderRadius="15px"
              style={boxStyle}
            >
              <Box
                display="flex"
                backgroundColor="#000000"
                borderRadius="15px 15px 0 0"
                width="100%"
                justifyContent="space-between" // Align the two boxes at both ends
                alignItems="center"
              >
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h5"
                  fontWeight="bold"
                  padding="15px"
                  color="#ffffff"
                >
                  Total Call Staus
                </Typography>
                <Box
                  backgroundColor="#ffffff"
                  width="30px"
                  alignItems="center"
                  borderRadius="15px"
                  mx="20px"
                >
                  <Typography
                    display="flex"
                    justifyContent="center"
                    variant="h5"
                    fontWeight="bold"
                    padding="5px"
                    color="#000000"
                  >
                    { total }

                  </Typography>
                </Box>
              </Box>

              <Box display="flex">
                <NumberCard
                  title="Prospecting"
                  value={reportResponse.prospecting}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Follow up leads"
                  value={reportResponse.followupleads}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Pursuing student"
                  value={reportResponse.pursuingstudent}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Future Prospecting"
                  value={reportResponse.futureprospecting}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
              </Box>

              <Box display="flex">
                <NumberCard
                  title="RNT"
                  value={reportResponse.rnt}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />

                <NumberCard
                  title="Not interested"
                  value={reportResponse.notinterested}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Not Connected"
                  value={reportResponse.notconnected}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Wrong enquiry"
                  value={reportResponse.wrongenquiry}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
              </Box>

              <Box display="flex">
                <NumberCard
                  title="Escalations"
                  value={reportResponse.escalations}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />

                <NumberCard
                  title="Applied with another agency"
                  value={reportResponse.appliedwithanotheragency}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Domestic enquiry"
                  value={reportResponse.domesticenquiry}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="R & E"
                  value={reportResponse.re}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
              </Box>

              <Box display="flex">
                <NumberCard
                  title="Just enquiry"
                  value={reportResponse.justenquiry}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Not eligible"
                  value={reportResponse.noteligible}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />

                <NumberCard
                  title="Applied"
                  value={reportResponse.applied}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Transferable leads"
                  value={reportResponse.transferableleads}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
              </Box>

              <Box display="flex">
                <NumberCard
                  title="Retargeting leads"
                  value={reportResponse.retargetingleads}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Documents collected"
                  value={reportResponse.documentscollected}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Office visit"
                  value={reportResponse.officevisit}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  icon={<ArrowOutwardRounded />}
                />
                <NumberCard
                  title="Registered"
                  value={reportResponse.registered}
                  bgColor="#f6f6f6"
                  textColor="#311B92"
                  handleItemClick={handleItemClick}
                  icon={<ArrowOutwardRounded />}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box flex="2">
          <Box
            m="0px 1px"
            display="flex"
            flexDirection="column"
            height="80vh"
            elevation="3"
          >
            <Box
              display="flex"
              marginRight="10px"
              justifyContent="space-between"
            >
              <Box display="flex" marginLeft="10px" justifyContent="start">
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h5"
                  color={"#000000"}
                  padding="10px"
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedStaffName}
                </Typography>
              </Box>

              <Box display="flex" marginRight="10px" justifyContent="end">
                <Button
                  variant={selectedButton === 1 ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handleFilterByToday()}
                  sx={{ margin: "0 2px" }}
                >
                  Today
                </Button>

                <Button
                  variant={selectedButton === 2 ? "contained" : "outlined"}
                  color="primary"
                  sx={{ margin: "0 2px" }}
                  onClick={() => handleFilterByYesterday()}
                >
                  Yesterday
                </Button>

                <Button
                  variant={selectedButton === 3 ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handleFilterByWeak(selectedStaff)}
                  sx={{ margin: "0 2px" }}
                >
                  This Week
                </Button>
              </Box>
            </Box>

            <Box flex="1" m="0px 0px" display="flex" flexDirection="coloumn">
              <Paper elevation={1} style={pieBoxStyle}>
                <PieChart reportResponse={reportResponse} />
              </Paper>
            </Box>

            {/* <Box
              m="0px 1px"
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
            >
              <Paper elevation={3} style={pieBoxStyle}>
                <PieChart reportResponse={reportResponse} />
              </Paper>
            </Box> */}
          </Box>
        </Box>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};
export default ReportDashBoard;
