import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "./theme";
import { LogoutRounded, NotificationsRounded } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ title, subTitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout logic here
    navigate(`/`, { replace: true });

    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box sx={{ p: "20px" }}>
        <Typography
          variant="h3"
          color={colors.bg[100]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>

        <Typography variant="h5" color={colors.textcolor[100]}>
          {subTitle}
        </Typography>
      </Box>
      <Box>
        <IconButton
          sx={{
            backgroundColor: "#f5f5f5",
            color: "#050505",
            p: "8px",
            mx: "8px",
          }}
        >
          <Avatar sx={{ bgcolor: "#ffffff", p: "8px" }}>
            <NotificationsRounded />
          </Avatar>
        </IconButton>

        <IconButton
          sx={{
            backgroundColor: "#f5f5f5",
            color: "#050505",
            p: "8px",
            mx: "8px",
          }}
          onClick={() => setOpen(true)}
        >
          <Avatar sx={{ bgcolor: "#ffffff", p: "8px" }}>
            <LogoutRounded />
          </Avatar>
        </IconButton>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiDialogTitle-root": {
              backgroundColor: "#000000",
              color: "#ffffff",
              textAlign: "center",
            },
            "& .MuiDialogContent-root": {
              backgroundColor: "#ffffff",
              color: "#000000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& .MuiDialogActions-root": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDialog-paper": {
              width: "400px", // Set width as needed
              height: "250px",
              borderRadius: "15px",
            },
          }}
        >
          <DialogTitle sx={{ fontSize: "15pt", fontWeight: 'bold' }}>Logout</DialogTitle>
          <DialogContent>
            <Typography variant="h5" color={colors.bg[100]}>
              Are you sure you want to logout?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} sx={{ fontWeight: 'bold' }}>Cancel</Button>
            <Button  onClick={handleLogout} color="error" sx={{ fontWeight: 'bold'}}>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Header;
