import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../../components/header";
import { Form, Formik, Field } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import {
  GetCallStatusData,
  GetCountryData,
  GetReportByFilter,
  GetSourceData,
  GetSourceInfoData,
  GetStaffs,
} from "../../data/repos/userRepo";
import { useMutation, useQuery } from "react-query";
import DropDown from "../../components/DateSelection";
import SelectionDropdown from "../../components/SelectionDropDown";
import DateSelection from "../../components/DateSelection";
import PieChart from "../../charts/pieChart";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../components/theme";
import { formatStaffName, formatedCountry, formatedFollowupCount, formatedLeadstatus, formatedPossibility } from "../../utls/commons";

import { useDispatch, useSelector } from 'react-redux';
import {
  setReportResponse,
  setSelectedRowId,
} from '../../globalcontext/reportSlice';

const ReportGeneration = ({ history, userId }) => {
  const [sourceName, setSourceName] = useState([]);
  const [sourceInfoName, setSourceInfoName] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [staffResponseData, setStaffResponseData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [callStatusResponseData, setCallStatusResponseData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  // const [reportResponse, setReportResponse] = useState([]);

  const dispatch = useDispatch();

  const {
    reportResponse = [],  
    selectedRowId,
  } = useSelector((state) => state.report);

  // const reportResponse = useSelector((state) => state.report.reportResponse);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const formattedData = reportResponse.map((item) => ({
    ...item,
    country : formatedCountry(item.country),
    possibility : formatedPossibility(item.possibility),
    staff: formatStaffName(item.staff),
    leadstatus: formatedLeadstatus(item.leadstatus),
  }));
  const gridRef = useRef(null);

  useEffect(() => {
    // Ensure gridRef and selectedRowId are available
    if (gridRef.current && selectedRowId !== null) {
      // Use setTimeout to defer scrolling until after rendering
      setTimeout(() => {
        const selectedRowElement = gridRef.current.querySelector(`[data-id="${selectedRowId}"]`);
        if (selectedRowElement) {
          selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100); // Adjust delay as needed
    }
  }, [selectedRowId, reportResponse]);


  const handleDelete = (key) => {
    setSelectedValues((prevSelectedValues) => {
      const { [key]: deletedValue, ...rest } = prevSelectedValues;
      delete rest[`${key}id`]; // Remove the corresponding ID
      return rest;
    });
  };

  // Function to handle change in the dropdown
  const handleDropdownChange = (keyId, keyName, id, name) => {
      keyId === ""
      ? setSelectedValues((prevState) => ({
          ...prevState,
          [keyName]: name,
        }))
      : setSelectedValues((prevState) => ({
          ...prevState,
          [keyId]: id,
          [keyName]: name,
        }));
    
    
  };

  const possibilityArray = [
    { id: 1, name: "Hot" },
    { id: 2, name: "Warm" },
    { id: 3, name: "Cold" },
  ];

  const profileCreated = [
    { id: 1, name: "Created" },
    { id: 0, name: "Not Created" },
  ];

  const followups = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];

  const ScheduledCalls = [
    { id: 1, name: "Rescheduled" },
    { id: 0, name: "Not Rescheduled" },
  ];

  const leadstate = [
    { id: 1, name: "Latest" },
    { id: 2, name: "Converted" },
    { id: 3, name: "Allocated" },
    { id: 4, name: "In progress" },
    { id: 5, name: "Closed" },
    { id: 6, name: "Give Up" },
  ];

  const { sources, isSourceLoading, isSourceError } = useQuery(
    "source",
    GetSourceData,
    {
      onSuccess: (sourceObj, variables, context) => {
        setSourceName(sourceObj?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { sourcesinfo, isSourceInfoLoading, isSourceInfoError } = useQuery(
    "sourceinfo",
    GetSourceInfoData,
    {
      onSuccess: (sourceInfoObj, variables, context) => {
        setSourceInfoName(sourceInfoObj?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { countries, isCountryLoading, isCountryError } = useQuery(
    "country",
    GetCountryData,
    {
      onSuccess: (countryObj, variables, context) => {
        setCountryName(countryObj?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { callStatus, isCallStatusLoading, isCallStatusError } = useQuery(
    "callStatus",
    GetCallStatusData,
    {
      onSuccess: (callStatusData, variables, context) => {
        setCallStatusResponseData(callStatusData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const pieBoxStyle = {
    width: "100%",
    margin: "10px",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const innerBox = {
    flex: "1",
    margin: "15px",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    padding: "10px",
  };

  const initialProfileValues = {
    startdate: "",
    enddate: "",
  };

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData, variables, context) => {
        setStaffResponseData(staffData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const columnData = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 0.2,
    },
    {
      field: "source",
      headerName: "Source",
      flex: 0.2,
    },
    {
      field: "sourceinfo",
      headerName: "Source Info",
      flex: 0.2,
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 0.2,
    },
    {
      field: "createdon",
      headerName: "Date",
      flex: 0.2,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.2,
    },
    // {
    //   field: "possibility",
    //   headerName: "Possibility",
    //   flex: 0.2,
    // },
    {
      field: "leadstatus",
      headerName: "Lead Status",
      flex: 0.2,
    },
    
    {
      field: "statusname",
      headerName: "Call Status",
      flex: 0.2,
    },
    {
      field: "staff",
      headerName: "Allocated to",
      flex: 0.2,
    },
    
  ];

  const handleGridItemClick = (params) => {
    

    console.log("Clicked row:", params);
    // Get the ID of the clicked row from the params
    const clickedItemId = params.row.id;
    dispatch(setSelectedRowId(clickedItemId));
    console.log("Clicked item ID:", clickedItemId);
    navigate(`/u/${userId}/task/lead/${clickedItemId}`);
  };

  const onReportRequested = () => {
   getReportMutation.mutate(selectedValues);
  };

  const getReportMutation = useMutation(GetReportByFilter, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      console.log("API Response Data:", data);
      if (data?.responsedata){
        console.log("Dispatching setReportResponse with:", data.responsedata);
        dispatch(setReportResponse(data?.responsedata));
        // console.log("**********Report Respnse*******", reportResponse)
      }else{
        toast.error("No data available with the selected filters. Try after altering filter conditions");
      }
      // const sum = Object.values(reportResponse).reduce((acc, currentValue) => acc + currentValue, 0)
      // setTotal(sum);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end", marginBottom:"10px"}}>
      <Box style={{ flexGrow: 1}} />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <Header title={"Reports"} subTitle={"Generate reports by filters"} />

      <Box display="flex" borderRadius="15px 15px 0 0">
        <Box flex="2">
          <Paper elevation={3} style={innerBox} margin="20px 0 0 0">
            <Box style={innerBox} margin="0px 0 0 0">
              <Formik
                initialValues={initialProfileValues}
                onSubmit={(values, formikBag) => {
                  onReportRequested(values, formikBag);
                }}
                validator={() => ({})}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="15px"
                      rowGap="35px"
                      gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                      sx={{
                        "& > div": {
                          gridColumn: isNonMobile ? undefined : "span 4",
                        },

                        "& .MuiInputLabel-root": {
                          color: "#333333", // Set the hint text color to dark color
                        },
                        "& input": {
                          color: "#000000",
                          borderRadius: "15px",
                          height: "10px",
                        },

                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: "#f6f6f6",
                          "& fieldset": {
                            borderColor: "transparent", // Remove border color
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent", // Remove border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", // Remove border color on focus
                          },
                        },
                      }}
                    >
                      <Field name="startdate">
                        {({ field }) => (
                          <DateSelection
                            label="Start Date"
                            value={selectedValues["startdate"]}
                            keyId=""
                            keyVal="startdate"
                            onChange={handleDropdownChange}
                            error={!!touched.startdate && !!errors.startdate}
                            helperText={touched.startdate && errors.startdate}
                          />
                        )}
                      </Field>

                      <Field name="endDate">
                        {({ field }) => (
                          <DateSelection
                            label="End Date"
                            value={selectedValues["enddate"]}
                            keyId=""
                            keyVal="enddate"
                            onChange={handleDropdownChange}
                            error={!!touched.enddate && !!errors.enddate}
                            helperText={touched.enddate && errors.enddate}
                          />
                        )}
                      </Field>

                      <Field name="staffname">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Staffs"
                            keyId="staffid"
                            keyVal="staff"
                            options={staffResponseData}
                            value={selectedValues["staffname"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Source">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Source"
                            keyId=""
                            keyVal="source"
                            options={sourceName}
                            value={selectedValues["source"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Source Info">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Source Info"
                            keyId=""
                            keyVal="sourceinfo"
                            options={sourceInfoName}
                            value={selectedValues["sourceinfo"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Preffered Country">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Country"
                            keyId=""
                            keyVal="country"
                            options={countryName}
                            value={selectedValues["country"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Lead State">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Lead State"
                            keyId="statusid"
                            keyVal="status"
                            options={leadstate}
                            value={selectedValues["statusid"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Lead Status">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Lead Status"
                            keyId="leadstatusid"
                            keyVal="leadstatus"
                            options={callStatusResponseData}
                            value={selectedValues["leadstatus"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Possibility">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Possibility"
                            keyId=""
                            keyVal="possibility"
                            options={possibilityArray}
                            value={selectedValues["possibility"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Followup Count">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Follow up"
                            keyId=""
                            keyVal="followupcount"
                            options={followups}
                            value={selectedValues["followup"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Scheduled Calls">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Scheduled Calls"
                            keyId="isrescheduledid"
                            keyVal="isrescheduled"
                            options={ScheduledCalls}
                            value={selectedValues["isrescheduled"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Field name="Student Profile">
                        {({ field }) => (
                          <SelectionDropdown
                            label="Student Profile"
                            keyId="studentprofileid"
                            keyVal="hasprofilecreated"
                            options={profileCreated}
                            value={selectedValues["hasprofilecreated"]}
                            onChange={handleDropdownChange}
                          />
                        )}
                      </Field>

                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          gridColumn: "span 1",
                          borderRadius: "25px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Generate
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            <Stack direction="row" spacing={1}>
              {Object.keys(selectedValues).map(
                (key) =>
                  // Check if the key ends with "Id" and skip creating chips for those keys
                  !key.endsWith("id") && (
                    <Chip
                      key={key}
                      label={selectedValues[key]}
                      onDelete={() => handleDelete(key)} // Pass the key to handleDelete function
                      color="primary"
                    />
                  )
              )}
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Box style={{ overflowY: "auto" }}>
        {reportResponse && reportResponse.length > 0 && (
          <Paper elevation={3} style={innerBox}>
            <Box
              m="20px 0 0 0"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  color: colors.bg[100],
                  cursor: "pointer",
                },
                "& .name-column--cell": {
                  color: colors.bg[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.bg[100],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#ffffff",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.bg[100],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                ref={gridRef}
                rows={formattedData}
                columns={columnData}
                onCellClick={handleGridItemClick}
                autoWidth
                autoColumnMode={true}
                slots={{toolbar: CustomToolbar}}
              />
               
            </Box>
          </Paper>
        )}
      </Box>

      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};

export default ReportGeneration;
