import { configureStore } from '@reduxjs/toolkit';
import listReducer from './listSlice';
import reportReducer from './reportSlice';

const store = configureStore({
  reducer: {
    list: listReducer,
    report: reportReducer,
  },
});

export default store;
