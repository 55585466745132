export const BASEURL = "https://api.learnyn.in";
// export const BASEURL = "http://0.0.0.0:80";
export const Login = "/user/login"
export const USERS = "/user/getall"

export const GetUser = "/user/getall"
export const AddUser = "/user/createstaff"
export const UpdateUser = "/user/updatestaff"
export const GetLeads = "/user/getleads"
export const UpdateLead = "/user/updatelead"
export const BulkUpdateLead = "/user/bulkupdatelead"

export const GetAllStaffs = "/user/getstaffs"
export const GetDepartments = "/user/getdept"
export const GetDesignations = "/user/getdesignation"
export const GetCountries = "/country/getall"

export const UploadBulkLeadApi = "/leads/createbulkmetalead"
export const AddStudentApi = "/leads/addstudent"
export const GetAllDataCount = "/leads/getallmetaleadcount"
export const GetAllMetaLeads = "/leads/getallmetaleads"

export const StaffwiseCallStatus = "/reports/getstaffwisereport"
export const StaffWiseLeadCount = "/leads/staffwisecallcount/"
export const GetReportByStatusKey = "/reports/getbykey"

export const GiveUpLead = "/leads/giveup"
export const studentProfileUpdate = "/leads/createprofile"
export const NameUpdate = "/leads/updatename"
export const GetLeadInfoById = "/leads/getleadinfo/"
export const GetCallStatus = "/leads/getcallstatus"
export const LeadCallUpdate = "/leads/callstatusupdate"

export const GetReportByFilterApi="/reports/getbyfilter"

export const GetAllSource="/user/getallsource"
export const GetAllSourceInfo="/user/getallsourceinfo"
export const GetAllCountry="/user/getallcountry"

export const GetCallRecords = "/getcallrecords"