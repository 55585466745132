import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const processData = (data, daysLength) => {
  // Check if data is provided and it's an array
  if (!data || !Array.isArray(data.responseData)) {
    console.error("Invalid data format or missing responseData");
    return [];
  }

  // Extract responseData from input
  const responseData = data.responseData;

  // Prepare an array of last 30 days formatted as "DD/MM/YYYY"
  const last30Days = Array.from({ length: daysLength }, (_, i) =>
    moment().subtract(i, "days").format("DD/MM/YYYY")
  ).reverse();

  // Initialize initialData with dates from last30Days and GiveUp count set to 0
  const initialData = last30Days.map((date) => ({ date, GiveUp: 0 }));

  // Aggregate the data based on "Give Up" status and date
  const aggregatedData = responseData.reduce((acc, item) => {
    // Extract the date in "DD/MM/YYYY" format from item's createdon
    const date = moment(item.createdon, "DD/MM/YYYY HH:mm:ss").format(
      "DD/MM/YYYY"
    );

    // Check if the statusname is "Give Up" and the date is within last30Days
    if (item.statusname === "Allocated" && last30Days.includes(date)) {
      // Find the index of the date in acc (aggregatedData)
      const index = acc.findIndex((d) => d.date === date);
      if (index >= 0) {
        // Increment the GiveUp count for the found date
        acc[index].GiveUp += 1;
      }
    }

    return acc;
  }, initialData);

  return aggregatedData;
};

const BarchartStacked = (responseData, days) => {
  const [chartData, setChartData] = useState([]);
  const [reportResponse, setReportResponse] = useState([]);
  console.log("Give up Chart:", responseData);
  // const getReportByFilter = useMutation(GetReportByFilter, {
  //   onMutate: () => {},
  //   onSuccess: (dataRes, variables, context) => {
  //     console.log("chartDataResponse:", dataRes?.responsedata);
  //     //   setReportResponse(data?.responsedata);
  //     const data = processData(dataRes?.responsedata);

  //     setChartData(data);

  //     // const sum = Object.values(reportResponse).reduce((acc, currentValue) => acc + currentValue, 0)
  //     // setTotal(sum);
  //   },
  //   onError: (error) => {
  //     console.error("Error:", error.message);
  //     toast.error(error.message);
  //   },
  // });

  useEffect(() => {
    // const request = {
    //   startdate: "10/05/2023",
    //   enddate: "07/06/2024",
    //   statusid: 3,
    //   status: "Allocated",
    // };

    // getReportByFilter.mutate(request);
    console.log("chartData", days)
    const data = processData(responseData, 30);
    setChartData(data);
  }, [responseData]);

  const CustomizedBar = (props) => {
    const { fill, x, y, width, height, radius } = props;
    return (
      <rect x={x} y={y} width={width} height={height} fill={fill} rx={radius} />
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="5 5" stroke="#ededed" vertical={false} />
          <XAxis
            dataKey="date"
            tick={{ fill: "#000000", fontSize: 12 }}
            interval={2} 
            tickLine={true}
            axisLine={false}
          />
          <YAxis
            tick={{ fill: "#000000", fontSize: 12 }}
            interval={0} 
            tickLine={true}
            axisLine={false}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#000000",
              borderColor: "#000000",
              fontSize: 14,
              borderRadius:10,
            }}
            cursor={{ fill: "#f0f0f0" }}
          />
          <Bar
            dataKey="GiveUp"
            stackId="a"
            fill="#87DB1C"
            shape={<CustomizedBar radius={5} />}
            barSize={25}
            background={{ fill: '#f6f6f6', opacity:0.4 }}
          />
         
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarchartStacked;
