import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../../components/header";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRef, useState } from "react";
import { AddUserLead, GetLeadsCount, UploadBulkData } from "../../data/repos/userRepo";
import * as yup from "yup";
import {
  Person2Outlined,
  AttachFileRounded,
  FileOpenRounded,
  TtyRounded,
  AssignmentRounded,
} from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import { CreateEmployee } from "../../data/repos/userRepo";

import { Formik } from "formik";
import { tokens } from "../../components/theme";
import FactCard from "../../components/factCard";

const CreateLead = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();
  const [leadUpdateResponse, setLeadUpdateResponse] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({});
  const [responseData, setResponsedata] = useState({});
  const [leadsCountResponseData, setLeadsCountResponseData] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const boxStyle = {
    flex: "2", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };
  const cardStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    padding: "15px",
  };

  const innerBox = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "15px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    source: "",
    sourceinfo: "",
    country: "",
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    name: yup.string(),
    email: yup.string().email("invalid email"),

    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),

    source: yup.string().required("required"),
    sourceinfo: yup.string(),
    country: yup.string(),
   
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
    fetchData(values);
  };

  const fetchData = (userData) => {
    createStudentMutation.mutate(userData);
  };

  const createStudentMutation = useMutation(AddUserLead, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      console.log("Response", responseData);
      toast.success(data.responsedescription);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const handleClearForm = () => {
    setFormValues(initialValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("leads", file);
    UploadFileMutation.mutate(formData);
  };

  const UploadFileMutation = useMutation(
    (formDataFile) => UploadBulkData(formDataFile),
    {
      onSuccess: (data, variables, context) => {
        setLeadUpdateResponse(data?.responseData);
        // Optionally, trigger a refetch of data here
        queryClient.invalidateQueries("myData");
        setIsLoading(false);
        toast.success(data.responsedescription);
      },
      onError: (error) => {
        // Handle errors here
        setIsLoading(false);
        console.error("Error:", error.message);
        toast.error(error.message);
      },
    }
  );

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const { data, isCountLoading, isError } = useQuery(
    "myData",
    () => GetLeadsCount(),
    {
      onSuccess: (data, variables, context) => {
        setLeadsCountResponseData(data?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  return (
    <Box m="10px 20px">
      <Header
        title={"Add Student"}
        subTitle={"Upload Bulk StudentData or create individual students here"}
      />
      <Box m="5px 10px" display="flex">
        <Paper style={cardStyle}>
          <Box sx={{ gridColumn: "span 1" }} display="flex" alignItems="center">
            <IconButton sx={{ color: "#050505" }}>
              <FileOpenRounded />
            </IconButton>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h5"
              fontWeight="bold"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              Bulk upload File as CSV
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box display="flex">
              <Box
                display="grid"
                gap="15px"
                rowGap="35px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: "span 3",
                  },

                  "& .MuiInputLabel-root": {
                    color: "#333333", // Set the hint text color to dark color
                  },
                  "& input": {
                    color: "#000000",
                    borderRadius: "15px",
                    height: "10px",
                  },

                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: "#f6f6f6",
                    "& fieldset": {
                      borderColor: "transparent", // Remove border color
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent", // Remove border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent", // Remove border color on focus
                    },
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Select a csv file to upload"
                  name="upload"
                  onChange={handleFileChange}
                  accept=".csv"
                  value={fileName}
                  sx={{ gridColumn: "span 4", width: "150%" }}
                ></TextField>
              </Box>

              <IconButton
                color="primary"
                component="span"
                variant="contained"
                onClick={handleButtonClick}
                sx={{
                  borderRadius: "15px", // Corner radius for the button
                  backgroundColor: "#00FF00", // Green color for the button
                  "&:hover": {
                    backgroundColor: "#00DD00", // Darker green on hover
                  },
                }}
              >
                <AttachFileRounded />
              </IconButton>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    mx: "10px",
                    borderRadius: "20px", // Set the border radius as needed
                    backgroundColor: "#000000", // Set the background color to black
                    color: "#ffffff", // Set the text color to white (optional)
                    "&:hover": {
                      backgroundColor: "#333333", // Change the background color on hover
                    },
                  }}
                >
                  Upload CSV
                </Button>
              )}
            </Box>

            {/* Submit button */}
          </form>
        </Paper>
        <Paper style={cardStyle}>
          <Box m="0px 1px" display="flex" width="100%" height="100%">
            <FactCard
              title="Total Meta Leads"
              value={leadsCountResponseData.bulkleadcount}
              bgColor="#B39DDB"
              textColor="#311B92"
              icon={<TtyRounded />}
            />

            <FactCard
              title="Assigned Leads"
              value={leadsCountResponseData.assignedleadscount}
              bgColor="#80CBC4"
              textColor="#004D40"
              icon={<AssignmentRounded />}
            />
            <FactCard
              title="New Leads"
              value={leadsCountResponseData.newleadscount}
              bgColor="#FFCC80"
              textColor="#E65100"
              icon={<AssignmentRounded />}
            />
          </Box>
        </Paper>
      </Box>
      <Box
        m="5px 10px"
        display="flex"
        width="98%"
        height="100%"
        flex="1"
        justifyContent="space-between"
      >
        <Paper style={boxStyle}>
          <Box style={innerBox}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="15px"
                    rowGap="35px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },

                      "& .MuiInputLabel-root": {
                        color: "#333333", // Set the hint text color to dark color
                      },
                      "& input": {
                        color: "#000000",
                        borderRadius: "15px",
                        height: "10px",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent", // Remove border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Remove border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Remove border color on focus
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{ gridColumn: "span 6" }}
                      display="flex"
                      alignItems="center"
                    >
                      <IconButton sx={{ color: colors.bg[100] }}>
                        <Person2Outlined />
                      </IconButton>
                      <Typography
                        display="flex"
                        justifyContent="start"
                        variant="h5"
                        fontWeight="bold"
                        color={colors.bg[100]}
                        sx={{ m: "0 0 0 0" }}
                      >
                        Create a new Lead
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      label="Name"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          name: e.target.value,
                        }));
                      }}
                      value={values.name}
                      name="name"
                      error={!!touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          email: e.target.value,
                        }));
                      }}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Mobile Number"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          mobile: e.target.value,
                        }));
                      }}
                      value={values.mobile}
                      name="mobile"
                      error={!!touched.mobile && !!errors.mobile}
                      helperText={touched.mobile && errors.mobile}
                      sx={{ gridColumn: "span 1" }}
                    />
                
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Source"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          source: e.target.value,
                        }));
                      }}
                      value={values.source}
                      name="source"
                      error={!!touched.source && !!errors.source}
                      helperText={touched.source && errors.source}
                      sx={{ gridColumn: "span 1" }}
                    />
                         <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Additional source Info"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          sourceinfo: e.target.value,
                        }));
                      }}
                      value={values.sourceinfo}
                      name="sourceinfo"
                      error={!!touched.sourceinfo && !!errors.sourceinfo}
                      helperText={touched.sourceinfo && errors.country}
                      sx={{ gridColumn: "span 1" }}
                    />
                      <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Country"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          country: e.target.value,
                        }));
                      }}
                      value={values.country}
                      name="country"
                      error={!!touched.country && !!errors.country}
                      helperText={touched.country && errors.country}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                  <Box display="flex">
                    <Box display="flex" justifyContent="center" mt="20px">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: "20px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Add Student
                      </Button>
                    </Box>
                    <Box display="flex" mt="20px" ml="15px">
                      <Button
                        type="reset"
                        variant="contained"
                        onClick={() => {
                          resetForm(); // Call resetForm to clear the form
                          handleClearForm(); // Assuming setFormValues is a function to reset your additional state
                        }}
                        sx={{
                          borderRadius: "20px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </Box>
  );
};
export default CreateLead;
