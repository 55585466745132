import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../components/theme";
import Header from "../../components/header";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import {
  BloodtypeOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  DateRange,
  DateRangeOutlined,
  EscalatorWarningOutlined,
  LanguageOutlined,
  Person2Outlined,
  WcOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CreateEmployee, GetDepartment, GetDepartments, GetDesignation, GetDesignations } from "../../data/repos/userRepo";
import { useMutation, useQuery } from "react-query";
import {
  getCurrentDateTime,
  formatCustomDate,
  formatCustomTime,
} from "../../utls/dateTimeUtls";

const Staffs = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formValues, setFormValues] = useState({});
  const [responseData, setResponsedata] = useState({});
  const { currentDate, currentTime } = getCurrentDateTime();
  const [designationResponseData, setDesignationResponseData] = useState([]);
  const [departmentResponseData, setDepartmentResponseData] = useState([]);

  const formattedDate = formatCustomDate(new Date(currentDate));
  const formattedTime = formatCustomTime(new Date(currentTime));
  console.log("currentDate1:", formattedDate);

  const handleFormSubmit = (values) => {
    console.log(values);
    fetchData(values);
  };
  const handleClearForm = () => {
    setFormValues(initialValues);
    // ... any other logic you want to perform on clear
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const boxStyle = {
    flex: "3", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };
  const box2Style = {
    flex: "2", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };
  const innerBox = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "15px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const mutation = useMutation(CreateEmployee, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      console.log("Response", responseData);
      toast.success(data.responsedescription);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });
  
  const fetchData = (userData) => {
    mutation.mutate(userData);
  };

  const { designations, isDesignationsLoading, isDesignationsError } = useQuery(
    "designation",
    GetDesignation,
    {
      onSuccess: (designations, variables, context) => {
        setDesignationResponseData(designations?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const { department, isDepartmentLoading, isDepartmentError } = useQuery(
    "department",
    GetDepartment,
    {
      onSuccess: (department, variables, context) => {
        setDepartmentResponseData(department?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );
  return (
    <div>
      <Header title={"Employees"} subTitle={"Manage Employees Profile"} />
      <Box
        m="5px 10px"
        display="flex"
        width="98%"
        height="100%"
        justifyContent="space-between"
      >
        <Paper elevation={3} style={boxStyle}>
          <Box style={innerBox}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="15px"
                    rowGap="35px"
                    gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 3",
                      },

                      "& .MuiInputLabel-root": {
                        color: "#333333", // Set the hint text color to dark color
                      },
                      "& input": {
                        color: "#000000",
                        borderRadius: "15px",
                        height: "10px",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent", // Remove border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Remove border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Remove border color on focus
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{ gridColumn: "span 3" }}
                      display="flex"
                      alignItems="center"
                    >
                      <IconButton sx={{ color: colors.bg[100] }}>
                        <Person2Outlined />
                      </IconButton>
                      <Typography
                        display="flex"
                        justifyContent="start"
                        variant="h5"
                        fontWeight="bold"
                        color={colors.bg[100]}
                        sx={{ m: "0 0 0 0" }}
                      >
                        Basic Information
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      label="Name"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          name: e.target.value,
                        }));
                      }}
                      value={values.name}
                      name="name"
                      error={!!touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      sx={{ gridColumn: "span 1" }}
                    />
                  <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                      <InputLabel
                        htmlFor="gender"
                        sx={{
                          transform: "translate(0, -25px)",
                          color: "#000000", // Set the label color to black
                        }} // Set the label color to black
                      >
                        Department
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Department"
                        name="department"
                        value={values.department}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedDepartment = departmentResponseData.find(
                            (department) => department.id === e.target.value
                          );
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            department: e.target.value,
                            departmentName: selectedDepartment.name,
                          }));
                        }}
                        sx={{
                          gridColumn: "span 1",
                          color: "#000000",
                          height: "43px",

                          "& .MuiInputLabel-root": {
                            transform: "translate(0, -25px)",
                          },
                          "& .MuiSelect-root": {
                            color: "#000000",
                            borderRadius: "15px",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f6f6f6",
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000000", // Set the icon color to black
                          },
                        }}
                        onBlur={handleBlur}
                        error={!!touched.gender && !!errors.gender}
                        helperText={touched.gender && errors.gender}
                      >
                        <MenuItem value="" disabled>
                          Select a department
                        </MenuItem>
                        {departmentResponseData.map((department) => (
                          <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
                        ))}
                       
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          email: e.target.value,
                        }));
                      }}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Mobile Number"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          mobile: e.target.value,
                        }));
                      }}
                      value={values.mobile}
                      name="mobile"
                      error={!!touched.mobile && !!errors.mobile}
                      helperText={touched.mobile && errors.mobile}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Address 1"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          address1: e.target.value,
                        }));
                      }}
                      value={values.address1}
                      name="address1"
                      error={!!touched.address1 && !!errors.address1}
                      helperText={touched.address1 && errors.address1}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Address 2"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          address2: e.target.value,
                        }));
                      }}
                      value={values.address2}
                      name="address2"
                      error={!!touched.address2 && !!errors.address2}
                      helperText={touched.address2 && errors.address2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      label="Password"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          password: e.target.value,
                        }));
                      }}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                      <InputLabel
                        htmlFor="gender"
                        sx={{
                          transform: "translate(0, -25px)",
                          color: "#000000", // Set the label color to black
                        }} // Set the label color to black
                      >
                        Designation
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Designation"
                        name="designation"
                        value={values.designation}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedDesig = designationResponseData.find(
                            (desig) => desig.id === e.target.value
                          );
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            designation: e.target.value,
                            desigValue: selectedDesig.name,
                          }));
                        }}
                        sx={{
                          gridColumn: "span 1",
                          color: "#000000",
                          height: "43px",

                          "& .MuiInputLabel-root": {
                            transform: "translate(0, -25px)",
                          },
                          "& .MuiSelect-root": {
                            color: "#000000",
                            borderRadius: "15px",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f6f6f6",
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000000", // Set the icon color to black
                          },
                        }}
                        onBlur={handleBlur}
                        error={!!touched.gender && !!errors.gender}
                        helperText={touched.gender && errors.gender}
                      >
                        <MenuItem value="" disabled>
                          Select a designation
                        </MenuItem>
                        {designationResponseData.map((designation) => (
                          <MenuItem key={designation.id} value={designation.id}>{designation.name}</MenuItem>
                        ))}
                       
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="date"
                      label="Date of Birth"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          dob: e.target.value,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                      value={values.dob}
                      name="dob"
                      error={!!touched.dob && !!errors.dob}
                      helperText={touched.dob && errors.dob}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="date"
                      label="Date of Join"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          doj: e.target.value,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                      value={values.doj}
                      name="doj"
                      error={!!touched.doj && !!errors.doj}
                      helperText={touched.doj && errors.doj}
                      sx={{ gridColumn: "span 1" }}
                    />

                    <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                      <InputLabel
                        htmlFor="gender"
                        sx={{
                          transform: "translate(0, -25px)",
                          color: "#000000", // Set the label color to black
                        }} // Set the label color to black
                      >
                        Gender
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Gender"
                        name="gender"
                        value={values.gender}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            gender: e.target.value,
                          }));
                        }}
                        sx={{
                          gridColumn: "span 1",
                          color: "#000000",
                          height: "43px",

                          "& .MuiInputLabel-root": {
                            transform: "translate(0, -25px)",
                          },
                          "& .MuiSelect-root": {
                            color: "#000000",
                            borderRadius: "15px",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f6f6f6",
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000000", // Set the icon color to black
                          },
                        }}
                        onBlur={handleBlur}
                        error={!!touched.gender && !!errors.gender}
                        helperText={touched.gender && errors.gender}
                      >
                        <MenuItem value="" disabled>
                          Select a gender
                        </MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Blood Group"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          bloodgroup: e.target.value,
                        }));
                      }}
                      value={values.bloodgroup}
                      name="bloodgroup"
                      error={!!touched.bloodgroup && !!errors.bloodgroup}
                      helperText={touched.bloodgroup && errors.bloodgroup}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Father/Spouse Name"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          parentname: e.target.value,
                        }));
                      }}
                      value={values.parentname}
                      name="parentname"
                      error={!!touched.parentname && !!errors.parentname}
                      helperText={touched.parentname && errors.parentname}
                      sx={{ gridColumn: "span 1" }}
                    />
                    {/* <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Gender"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        gender: e.target.value,
                      }));
                    }}
                    value={values.gender}
                    name="gender"
                    error={!!touched.gender && !!errors.gender}
                    helperText={touched.gender && errors.gender}
                    sx={{ gridColumn: "span 1" }}
                  /> */}

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Nationality"
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          nationality: e.target.value,
                        }));
                      }}
                      value={values.nationality}
                      name="nationality"
                      error={!!touched.nationality && !!errors.nationality}
                      helperText={touched.nationality && errors.nationality}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                  <Box display="flex">
                    <Box display="flex" justifyContent="start" mt="20px">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: "20px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Create User
                      </Button>
                    </Box>
                    <Box display="flex" mt="20px" ml="15px">
                      <Button
                        type="reset"
                        variant="contained"
                        onClick={() => {
                          resetForm(); // Call resetForm to clear the form
                          handleClearForm(); // Assuming setFormValues is a function to reset your additional state
                        }}
                        sx={{
                          borderRadius: "20px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Paper>
        <Paper elevation="3" style={box2Style}>
          <Box width="100%" height="100%" borderRadius="15px">
            <Box
              sx={{ gridColumn: "span 3", m: "0px" }}
              display="flex"
              alignItems="center"
              backgroundColor="#000000"
              textAlign="center"
              padding="5px"
              borderRadius="15px 15px 0 0"
            >
              <IconButton sx={{ color: "#fff" }}>
                <Person2Outlined />
              </IconButton>
              <Typography
                display="flex"
                justifyContent="start"
                variant="h5"
                fontWeight="bold"
                color="#fff"
                sx={{ m: "0 0 0 0" }}
              >
                User Preview
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" width="100%">
              <Box
                display="flex"
                flex="1"
                alignItems="center"
                justifyContent="start"
              >
                <Avatar
                  fullWidth
                  alt="Mavex Mentr"
                  src="/thumb.png"
                  sx={{
                    width: 56,
                    height: 56,
                    padding: "15px",
                    bgcolor: "#E3F2FD",

                    m: "20px 0 10px 15px",
                  }}
                ></Avatar>
                <Box marginLeft="15px">
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    fontWeight="bold"
                    color={colors.bg[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    {formValues.name}
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    fontWeight="bold"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    {formValues.desigValue}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="end"
                flex="1"
                justifyContent="end"
                marginRight="15px"
              >
                <Typography
                  variant="h6"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  Date: {formattedDate}
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  Time: {formattedTime}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding="15px"
            >
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <EmailOutlinedIcon />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Email
                  </Typography>
                </Box>

                <Typography
                  display="flex"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.email}
                </Typography>
              </div>

              <div style={{ flex: 1, m: "10px" }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <PhoneIphoneOutlinedIcon />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Mobile
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.mobile}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <WorkOutlineOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Department
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.departmentName}
                </Typography>
              </div>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding="15px"
            >
              <div style={{ flex: 1, m: "10px" }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <BusinessOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Address 1
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.address1}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <BusinessOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Address 2
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.address2}
                </Typography>
              </div>

              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <BusinessCenterOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Designation
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.desigValue}
                </Typography>
              </div>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding="15px"
            >
              <div style={{ flex: 1, m: "10px" }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <DateRangeOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Date of birth
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.dob}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <DateRange />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Date of Join
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.doj}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <BloodtypeOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Blood group
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.bloodgroup}
                </Typography>
              </div>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding="15px"
            >
              <div style={{ flex: 1, m: "10px" }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <WcOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Gender
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.gender}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <EscalatorWarningOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Guardian
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.parentname}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <IconButton sx={{ color: colors.textcolor[100] }}>
                    <LanguageOutlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    variant="h6"
                    color={colors.textcolor[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Nationality
                  </Typography>
                </Box>
                <Typography
                  display="flex"
                  justifyContent="start"
                  variant="h6"
                  paddingLeft="8px"
                  color={colors.bg[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {formValues.nationality}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  department: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
  dob: yup.string().required("required"),
  doj: yup.string().required("required"),
  bloodgroup: yup.string().required("required"),
  parentname: yup.string().required("required"),
  gender: yup.string().required("required"),
  nationality: yup.string().required("required"),
  designation: yup.string().required("required"),
});
const initialValues = {
  name: "",
  department: "",
  email: "",
  mobile: "",
  address1: "",
  address2: "",
  dob: "",
  doj: "",
  bloodgroup: "",
  parentname: "",
  gender: "",
  nationality: "",
  designation: "",
};

export default Staffs;
