import { useState } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQueryClient, useMutation, useQuery } from "react-query";

import {Person2Outlined } from "@mui/icons-material";
import { tokens } from "./theme";
import { UpdateStudentProfile } from "../data/repos/userRepo";

const StudentProfileUpdateForm = () => {
  const { id } = useParams();
  const studentId = parseInt(id, 10);
  const queryClient = useQueryClient();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formValues, setFormValues] = useState({});
  const [submitResponsedata, setSubmitResponsedata] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",
    maxHeight: "95vh",
    overflowY: "auto",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };
  const previewBoxStyle = {
    margin: "0px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
  };

  const innerBox = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "15px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const initialProfileValues = {
    twelthhmark: "",
    twelthboard: "",
    twelthpassyear: "",
    englishmark: "",
    ugmark: "",
    ugboard: "",
    ugcourse: "",
    ugpassyear: "",
    pgmark: "",
    pgboard: "",
    pgcourse: "",
    pgpassyear: "",
    englishprof: "",
    engscore: "",
    occupation: "",
    salary: "",
  };

  const onProfileSubmitted = (values, formikBag) => {
    const staffId = JSON.parse(localStorage.getItem("loginInfo")).id;
    setFormValues(values);
    const studentIdNumber = parseInt(id, 10);
    const updateRequestObj = {
      hsmark: values.twelthhmark,
      hsboard: values.twelthboard,
      hspassyear: values.twelthpassyear,
      hsengmark: values.englishmark,

      ugmark: values.ugmark,
      uguniversity: values.ugboard,
      ugcourse: values.ugcourse,
      ugpassyear: values.ugpassyear,

      pgmark: values.pgmark,
      pguniversity: values.pgboard,
      pgcourse: values.pgcourse,
      pgpassyear: values.pgpassyear,

      englishprof: values.englishprof,
      score: values.engscore,
      occupation: values.occupation,
      salary: values.salary,

      staffid: staffId,
      studentId: studentIdNumber,
    };

    console.log("profile requestObj:", updateRequestObj);

    if (studentId !== undefined && studentId !== null && studentId !== "") {
      profileUpdateMutation.mutate(updateRequestObj);
      formikBag.resetForm();
    } else {
      toast.error("invalid operation!");
    }
  };

  const profileUpdateMutation = useMutation(UpdateStudentProfile, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setSubmitResponsedata(data?.submitResponsedata);
      toast.success(data.responsedescription);
      console.log("Attempting to invalidate queries for:", [
        "leads",
        studentId,
      ]);
      queryClient.invalidateQueries(["leads", studentId]);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  return (
    <Paper style={boxStyle}>
      <Box
        width="100%"
        height="100%"
        borderRadius="15px"
        style={previewBoxStyle}
      >
        <Box style={innerBox}>
          <Formik
            initialValues={initialProfileValues}
            onSubmit={(values, formikBag) => {
              onProfileSubmitted(values, formikBag);
            }}
            validator={() => ({})}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="15px"
                  rowGap="35px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },

                    "& .MuiInputLabel-root": {
                      color: "#333333", // Set the hint text color to dark color
                    },
                    "& input": {
                      color: "#000000",
                      borderRadius: "15px",
                      height: "10px",
                    },

                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: "#f6f6f6",
                      "& fieldset": {
                        borderColor: "transparent", // Remove border color
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent", // Remove border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove border color on focus
                      },
                    },
                  }}
                >
                  <Box
                    sx={{ gridColumn: "span 3" }}
                    display="flex"
                    alignItems="center"
                  >
                    <IconButton sx={{ color: colors.bg[100] }}>
                      <Person2Outlined />
                    </IconButton>
                    <Typography
                      display="flex"
                      justifyContent="start"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.bg[100]}
                      sx={{ m: "0 0 0 0" }}
                    >
                      Update student profile
                    </Typography>
                  </Box>

                  <TextField
                    fullWidth
                    type="text"
                    label="12th Mark"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        twelthhmark: e.target.value,
                      }));
                    }}
                    value={values.twelthhmark}
                    name="twelthhmark"
                    error={!!touched.twelthhmark && !!errors.twelthhmark}
                    helperText={touched.twelthhmark && errors.twelthhmark}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="12th Board"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        twelthboard: e.target.value,
                      }));
                    }}
                    value={values.twelthboard}
                    name="twelthboard"
                    error={!!touched.twelthboard && !!errors.twelthboard}
                    helperText={touched.twelthboard && errors.twelthboard}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Pass Year"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        twelthpassyear: e.target.value,
                      }));
                    }}
                    value={values.twelthpassyear}
                    name="twelthpassyear"
                    error={!!touched.twelthpassyear && !!errors.twelthpassyear}
                    helperText={touched.twelthpassyear && errors.twelthpassyear}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="12th English Mark"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        englishmark: e.target.value,
                      }));
                    }}
                    value={values.englishmark}
                    name="englishmark"
                    error={!!touched.englishmark && !!errors.englishmark}
                    helperText={touched.englishmark && errors.englishmark}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="UG Course Name"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        ugcourse: e.target.value,
                      }));
                    }}
                    value={values.ugcourse}
                    name="ugcourse"
                    error={!!touched.ugcourse && !!errors.ugcourse}
                    helperText={touched.ugcourse && errors.ugcourse}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="UG Mark"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        ugmark: e.target.value,
                      }));
                    }}
                    value={values.ugmark}
                    name="ugmark"
                    error={!!touched.ugmark && !!errors.ugmark}
                    helperText={touched.ugmark && errors.ugmark}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="UG University"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        ugboard: e.target.value,
                      }));
                    }}
                    value={values.ugboard}
                    name="ugboard"
                    error={!!touched.ugboard && !!errors.ugboard}
                    helperText={touched.ugboard && errors.ugboard}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="UG Pass Year"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        ugpassyear: e.target.value,
                      }));
                    }}
                    value={values.ugpassyear}
                    name="ugpassyear"
                    error={!!touched.ugpassyear && !!errors.ugpassyear}
                    helperText={touched.ugpassyear && errors.ugpassyear}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="PG Course Name"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        pgcourse: e.target.value,
                      }));
                    }}
                    value={values.pgcourse}
                    name="pgcourse"
                    error={!!touched.pgcourse && !!errors.pgcourse}
                    helperText={touched.pgcourse && errors.pgcourse}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="PG Mark"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        pgmark: e.target.value,
                      }));
                    }}
                    value={values.pgmark}
                    name="pgmark"
                    error={!!touched.pgmark && !!errors.pgmark}
                    helperText={touched.pgmark && errors.pgmark}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="PG University"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        pgboard: e.target.value,
                      }));
                    }}
                    value={values.pgboard}
                    name="pgboard"
                    error={!!touched.pgboard && !!errors.pgboard}
                    helperText={touched.pgboard && errors.pgboard}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Pass Year"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        pgpassyear: e.target.value,
                      }));
                    }}
                    value={values.pgpassyear}
                    name="pgpassyear"
                    error={!!touched.pgpassyear && !!errors.pgpassyear}
                    helperText={touched.pgpassyear && errors.pgpassyear}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="English Proficiancy"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        englishprof: e.target.value,
                      }));
                    }}
                    value={values.englishprof}
                    name="englishprof"
                    error={!!touched.englishprof && !!errors.englishprof}
                    helperText={touched.englishprof && errors.englishprof}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Score"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        engscore: e.target.value,
                      }));
                    }}
                    value={values.engscore}
                    name="engscore"
                    error={!!touched.engscore && !!errors.engscore}
                    helperText={touched.engscore && errors.engscore}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Occupation"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        occupation: e.target.value,
                      }));
                    }}
                    value={values.occupation}
                    name="occupation"
                    error={!!touched.occupation && !!errors.occupation}
                    helperText={touched.occupation && errors.occupation}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Salary"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -25px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        salary: e.target.value,
                      }));
                    }}
                    value={values.salary}
                    name="salary"
                    error={!!touched.salary && !!errors.salary}
                    helperText={touched.salary && errors.salary}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <Box display="flex" sx={{ gridColumn: "span 2" }}>
                    <Box display="flex" justifyContent="center" mb="10px" mt="0px">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: "20px", // Set the border radius as needed
                          backgroundColor: "#000000", // Set the background color to black
                          color: "#ffffff", // Set the text color to white (optional)
                          "&:hover": {
                            backgroundColor: "#333333", // Change the background color on hover
                          },
                        }}
                      >
                        Update Profile
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};
export default StudentProfileUpdateForm;
